<template>
  <div class="components">
    <!-- 标题部分-->
    <div class="header">
      <div>
        <h4 class="header-title">分类下贴图</h4>
        <p class="header-subtitle">当前已上传贴图{{ listData.total }}个</p>
      </div>
      <div v-if="tabGps != 0">
        <button class="btn" @click="add">新增贴图</button>
        <button class="btn" @click="keepBTN">保存</button>
        <!--                <button class="btn" @click="excelImport">Excel导入</button>-->
        <button class="btn" @click="deleteChartletList">清空</button>
      </div>
    </div>
    <!-- 贴图展示部分-->
    <div @scroll="scrollChange" class="content">
      <div v-for="(item, index) in listData.data" :key="index" class="content-item">
        <el-card :body-style="{ padding: '0px' }">
          <!-- 贴图展示上传-->
          <div class="content-item-header">
            <div class="content-item-header-bottom">
              <el-upload
                v-if="!item.whole_picture"
                class="content-item-header-upload"
                drag
                action="#"
                :multiple="false"
                :limit="1"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="
                  (file) => {
                    handleAvatarSuccess(file, item);
                  }
                "
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  拖放或
                  <em>点击上传</em>
                </div>
              </el-upload>
              <el-image
                v-else
                :src="item.whole_picture"
                class="content-item-header-img"
                fit="cover"
                :preview-src-list="[item.whole_picture]"
              />
            </div>
            <div class="content-item-header-button-group" v-if="tabGps != 0">
              <span v-if="item.id > 0" @click="editClassifyDialog.showFunc(item.id, index)">
                修改分类
              </span>
              <span @click="edit(item)">编辑</span>
              <span @click="delClassify(index)">删除</span>
            </div>
          </div>

          <!-- 贴图属性-->
          <div class="content-item-body">
            <template v-if="item.id > 0">
              <template v-if="item.is_edit">
                <el-form>
                  <el-form-item
                    :rules="{
                      required: true,
                      message: '请输入贴图名称',
                      trigger: 'change',
                    }"
                  >
                    <el-tooltip content="请输入贴图名称">
                      <el-input
                        class="content-item-body-form-item"
                        size="small"
                        v-model="item.title"
                        placeholder="贴图名称"
                      ></el-input>
                    </el-tooltip>
                  </el-form-item>
                  <el-form-item
                    :rules="{
                      required: true,
                      message: '请输入贴图编号',
                      trigger: 'change',
                    }"
                  >
                    <el-tooltip content="请输入贴图编号">
                      <el-input
                        class="content-item-body-form-item"
                        size="small"
                        v-model="item.number"
                        placeholder="贴图编号"
                      ></el-input>
                    </el-tooltip>
                  </el-form-item>

                  <el-form-item>
                    <el-tooltip content="厂家编号">
                      <el-input
                        class="content-item-body-form-item"
                        size="small"
                        v-model="item.constructionNumber"
                        placeholder="厂家编号"
                      ></el-input>
                    </el-tooltip>
                  </el-form-item>

                  <el-form-item>
                    <el-tooltip content="公库品牌名">
                      <el-input
                        class="content-item-body-form-item"
                        size="small"
                        v-model="item.publicBrandName"
                        placeholder="公库品牌名"
                      ></el-input>
                    </el-tooltip>
                  </el-form-item>
                  <el-form-item>
                    <el-tooltip content="公库供应链名">
                      <el-input
                        class="content-item-body-form-item"
                        size="small"
                        v-model="item.publicSupplyChainName"
                        placeholder="公库供应链名"
                      ></el-input>
                    </el-tooltip>
                  </el-form-item>
                  <div class="save-bar">
                    <a type="text" @click="saveData(item)">Save</a>
                  </div>
                </el-form>
              </template>
              <template v-else>
                <div>
                  <p>{{ item.title ? item.title : '-' }}</p>
                  <p>{{ item.number ? item.number : '-' }}</p>
                  <p>
                    {{ item.constructionNumber ? item.constructionNumber : '-' }}
                  </p>
                  <p>{{ item.publicBrandName ? item.publicBrandName : '-' }}</p>
                  <p>
                    {{ item.publicSupplyChainName ? item.publicSupplyChainName : '-' }}
                  </p>
                </div>
              </template>
            </template>
            <!-- 新增贴图-->
            <template v-else>
              <el-form ref="newForm">
                <el-form-item
                  :rules="{
                    required: true,
                    message: '请输入贴图名称',
                    trigger: 'change',
                  }"
                >
                  <el-input
                    class="content-item-body-form-item"
                    size="small"
                    v-model="item.title"
                    placeholder="贴图名称"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :rules="{
                    required: true,
                    message: '请输入贴图编号',
                    trigger: 'change',
                  }"
                >
                  <el-input
                    class="content-item-body-form-item"
                    size="small"
                    v-model="item.number"
                    placeholder="贴图编号"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  :rules="{
                    required: true,
                    message: '请输入厂家编号',
                    trigger: 'change',
                  }"
                >
                  <el-input
                    class="content-item-body-form-item"
                    size="small"
                    v-model="item.constructionNumber"
                    placeholder="厂家编号"
                  ></el-input>
                </el-form-item>

                <el-form-item>
                  <el-input
                    class="content-item-body-form-item"
                    size="small"
                    v-model="item.publicBrandName"
                    placeholder="公库品牌名"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input
                    class="content-item-body-form-item"
                    size="small"
                    v-model="item.publicSupplyChainName"
                    placeholder="公库供应链名"
                  ></el-input>
                </el-form-item>
              </el-form>
            </template>
          </div>
        </el-card>
      </div>
    </div>
    <el-dialog
      title="修改贴图分类"
      :visible.sync="editClassifyDialog.isShow"
      width="450px"
      center
      :close-on-click-modal="false"
    >
      <el-cascader
        v-model="editClassifyDialog.formData.classify_ids"
        :options="editClassifyDialog.options"
        :props="{ label: 'name', value: 'id' }"
        style="width: 400px"
      ></el-cascader>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editClassifyDialog.isShow = false">取 消</el-button>
        <el-button type="primary" @click="editClassifySubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getmappinglist,
  modelsConfig_list,
  setclassifysave,
  setmappingdel,
  setmappingsave,
  setmappingsaveitem,
} from '@/common/modelList';
import { Loading } from 'element-ui';
export default {
  name: 'sticker-list',
  props: {
    // 贴图分类ID
    id: {
      type: Number,
      default: () => 0,
    },
    // 空间ID
    tabGps: {
      type: Number,
      default: () => 0,
    },
  },

  data() {
    return {
      rules: {
        input1: [{ required: true, message: '请输入贴图名称', trigger: 'change' }],
        input2: [{ required: true, message: '请输入贴图编号', trigger: 'change' }],
      },
      options: [],
      scroll: 0,
      listData: {
        data: [], // 列表
        current_page: 1, // 当前页
        last_page: 0, // 总页数
        per_page: 10, // 每页条数
        total: 0, // 总数据条数
      },
      editClassifyDialog: {
        isShow: false,
        options: [],
        formData: {
          index: 0,
          id: 0,
          classify_ids: [],
        },
        showFunc: function (id, index) {
          this.formData.index = index;
          this.formData.id = id;
          this.formData.classify_ids = [];
          this.isShow = true;
          if (this.options.length === 0) {
            modelsConfig_list().then((res) => {
              let data = (arr) => {
                for (let i = 0; i < arr.length; i++) {
                  if (arr[i].children.length !== 0) {
                    data(arr[i].children);
                  } else {
                    arr[i].children = '';
                  }
                }
              };
              console.log(res);
              data(res.result.config_list);
              this.options = res.result.config_list;
            });
          }
        },
      },
    };
  },
  created() {
    // this.init();
  },
  watch: {
    id: function (newValue, oldValue) {
      console.log(
        `-------- 贴图子组件监听到ID发生变化，oldValue: ${oldValue}，newValue: ${newValue}`,
      );
      this.init();
    },
    tabGps: {
      handler: async function (newValue, oldValue) {
        console.log(
          `-------- 贴图子组件监听到tabGps发生变化，oldValue: ${oldValue}，newValue: ${newValue}`,
        );
        await this.init(newValue);
      },
      immediate: false,
    },
  },
  methods: {
    delClassify(index) {
      if (this.listData.data[index].id) {
        let formData = {
          del_id: [this.listData.data[index].id],
          del_url: [this.listData.data[index].whole_picture],
        };
        setmappingdel(formData).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.listData.data.splice(index, 1);
          } else {
            this.$message.error(res.msg);
            throw new Error(res.msg);
          }
        });
      } else {
        this.listData.data.splice(index, 1);
      }
    },
    editClassifySubmit() {
      let postData = {
        classify_id:
          this.editClassifyDialog.formData.classify_ids[
            this.editClassifyDialog.formData.classify_ids.length - 1
          ],
        ids: [this.editClassifyDialog.formData.id],
      };
      setclassifysave(postData).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.msg);
          this.editClassifyDialog.isShow = false;
          this.listData.data.splice(this.editClassifyDialog.formData.index, 1);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    scrollChange(event) {
      let el = event.target;
      if (el.scrollLeft + el.clientWidth >= el.scrollWidth) {
        if (this.listData.current_page >= this.listData.last_page) {
          console.log(
            `-------- 已经请求到最后一页了，current_page: ${this.listData.current_page}，last_page: ${this.listData.last_page}`,
          );
          return;
        }
        this.listData.current_page++;
        this.loadData();
      }
    },
    async init(val = null) {
      console.log('-------- 贴图子组件初始化:' + this.tabGps);
      this.listData.current_page = 1;
      this.listData.last_page = 0;
      this.listData.per_page = 10;
      this.listData.total = 0;
      await this.loadData(val);
    },

    async loadData(val = null) {
      if (this.listData.current_page <= 1) {
        this.listData.data = [];
      }

      if (val == null) {
        val = this.tabGps;
      }

      const postData = {
        classify_id: this.id,
        space_id: val,
        page: this.listData.current_page,
        limit: this.listData.per_page,
      };
      const loadingInstance = Loading.service({
        target: '.components',
        lock: true,
        text: '加载贴图中...',
      });

      let res = await getmappinglist(postData);
      if (res.code !== 200) {
        console.log('贴图子组件请求出错', res);
        return;
      }

      if (!res.result.data.length && res.result.data.length === 0) {
        loadingInstance.close();
        this.$loading.close;
        return;
      }

      this.listData.current_page = res.result.current_page;
      this.listData.last_page = res.result.last_page;
      this.listData.per_page = res.result.per_page;
      this.listData.total = res.result.total;

      this.listData.data.push(...res.result.data);
      loadingInstance.close();
      this.$loading.close;
    },
    add() {
      if (this.id <= 0) {
        this.$message.warning('请选择分类');
        return;
      }
      this.listData.data.unshift({
        whole_picture: '',
        title: '',
        number: '',
        publicBrandName: '',
        publicSupplyChainName: '',
        image: '',
        id: 0,
      });
    },
    keepBTN() {
      if (this.listData.data.length > 0) {
        let dt = new FormData();
        this.listData.data.forEach((el, index) => {
          if (!el.id) {
            dt.append(`save_val[${index}][id]`, '');
            dt.append(`save_val[${index}][classify_id]`, this.id);
            dt.append(`save_val[${index}][title]`, el.title);
            dt.append(`save_val[${index}][number]`, el.number);
            dt.append(`save_val[${index}][publicBrandName]`, el.publicBrandName);
            dt.append(`save_val[${index}][constructionNumber]`, el.constructionNumber);

            dt.append(`save_val[${index}][publicSupplyChainName]`, el.publicSupplyChainName);
            dt.append(`save_val[${index}][only]`, el.image.uid);
            dt.append(`picture_${el.image.uid}`, el.image);
            dt.append(`del_file[${index}]`, '');
            dt.append(`space_id`, this.tabGps);
          }
        });
        setmappingsave(dt).then((res) => {
          if (res.code) {
            this.init();
            this.$message.success(res.msg);
          }
        });
      } else {
        this.$message.warning('未新增贴图');
      }
    },
    // 清空未保存
    deleteChartletList() {
      //filter数组过滤器，返回一个数组
      this.listData.data = this.listData.data.filter((item) => {
        return item.id > 0;
      });
      this.$message.success('清空成功');
    },
    handleAvatarSuccess(file, item) {
      item.whole_picture = URL.createObjectURL(file.raw);
      item.image = file.raw;
      let weidu = file.name.substring(
        0,
        file.name.indexOf('.jpg' || '.jpeg' || '.png' || '.JPG' || '.JPEG' || '.PNG'),
      );
      item.title = weidu;
    },

    edit(item) {
      item.is_edit = true;
      this.$forceUpdate();
    },
    async saveData(item) {
      console.log(item);
      let resp = await setmappingsaveitem({
        id: item.id,
        number: item.number,
        publicBrandName: item.publicBrandName,
        constructionNumber: item.constructionNumber,
        publicSupplyChainName: item.publicSupplyChainName,
        title: item.title,
      });

      console.log(resp);
      this.$message(resp.msg);
      item.is_edit = false;
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped lang="scss">
.components {
  margin-top: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d5d5d5;
    margin: 20px 0;

    &-title {
      font-size: 18px;
      font-weight: 800;
      color: #0c0c0c;
      opacity: 1;
      width: 100%;
      margin: 0;
    }

    &-subtitle {
      font-size: 14px;
      display: block;
      width: 100%;
      text-align: right;
    }

    .btn {
      margin: 5px;
      width: 109px;
      font-size: 14px;
      font-weight: 500;
      background-color: #ffffff;
      border: 1px solid #707070;
      padding: 6px 0;
      text-align: center;
      cursor: pointer;
    }
    .btn:hover {
      background-color: #29303e;
      color: white;
    }
  }

  .content {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;

    &-item {
      display: inline-block;
      margin-right: 10px;
      width: 180px;
      .save-bar {
        text-align: center;
        cursor: pointer;
        font-size: 12px;
        &:hover {
          color: #4099ff;
        }
      }
      &-header {
        width: 180px;
        height: 180px;
        display: flex;
        position: relative;

        &-bottom {
          width: 180px;
          height: 180px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
        }

        .el-icon-upload {
          font-size: 30px;
        }

        ::v-deep .el-upload .el-upload-dragger {
          width: 178px;
          height: 178px;
          border-radius: 0;
        }
        .el-upload__text {
          display: block;
          margin: 0;
          padding: 0;
          line-height: 10px;
        }
        &-upload {
          margin: auto;
          width: 180px;
          height: 180px;
        }
        &-img {
          width: 180px;
          height: 180px;
        }

        &-button-group {
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.74);
          display: block;
          width: 100%;
          color: white;
          text-align: center;
          opacity: 0;

          span {
            display: inline-block;
            width: 33%;
            text-align: center;
            cursor: pointer;
            font-size: 12px;
            line-height: 30px;
          }
        }
        &:hover,
        &-button-group:hover {
          opacity: 1;
        }
      }

      &-body {
        height: 230px;
        margin: 5px 10px 25px 10px;
        &-form-item {
          width: 160px;
          margin-top: 5px;
        }
        p {
          font-size: 16px;
          font-weight: bold;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .el-form-item {
          margin: 0;
        }
      }
    }
  }
}
</style>
