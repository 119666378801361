<template>
  <div class="hello">
    <div class="header">
      <div class="header-name">
        <p class="header-name-left">模型库</p>
      </div>
      <div style="text-align: left">
        <category :categoryData="data" :events="events"></category>
      </div>
      <div class="header-name">
        <p class="header-name-left">空间</p>
      </div>
      <div>
        <div class="gpsBtn" v-if="getuploadspaceData">
          <span
            :class="{ one: item.id != 0, publicItem: item.id == 0 }"
            :style="{
              backgroundColor: tabGps == item.id ? '#29303e' : '',
              color: tabGps == item.id ? 'white' : '',
            }"
            @click="tabComponent(item.id)"
            v-for="item in getuploadspaceData"
            :key="item.id"
          >
            {{ item.name }}
          </span>
        </div>
      </div>
      <el-tabs style="margin: 18px 0" type="border-card" v-model="activeName">
        <el-tab-pane label="模型管理" name="model">
          <div class="headerModel">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #d5d5d5;
                margin: 20px 0;
              "
            >
              <div>
                <h4 class="subClassTitle">分类下模型</h4>
                <p style="font-size: 14px; display: block; width: 100%; text-align: right">
                  当前分类下已上传模型{{ modelOtherData.total }}个
                </p>
              </div>
              <div v-if="tabGps != 0">
                <button style="margin: 5px" class="btn" @click="addModel" v-if="isShow">
                  新增模型
                </button>
                <button style="margin: 5px" class="btn" @click="ProhibitToEdit" v-if="!isShow">
                  新增模型
                </button>
              </div>
            </div>

            <CartControll
              :modelData="modelData"
              :chartletListArr="chartletListArr"
              @ModelData="modelInit"
              :tabGps="tabGps"
              ref="CartControllArr"
              @modelNextPage="modelNextPage"
              @loadAllChartletListArr="loadAllChartletListArr"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="贴图管理" name="mapper">
          <StickerList
            v-if="getuploadspaceData"
            ref="sticker_list"
            :id="id"
            :tab-gps="tabGps"
          ></StickerList>
        </el-tab-pane>
      </el-tabs>

      <!-- 重构了贴图部分-->
    </div>
  </div>
</template>

<script>
import CartControll from '@/components/CartControll';
import {
  getconfiglist,
  getmappinglist,
  model_baseIndex,
  modelsConfig_list,
  setmappingdel,
} from '@/common/modelList';
// import ArrList from '@/components/arrList.vue';
// import Bus from '@/common/bus.js';
import Category from '@/components/category.vue';
import StickerList from './components/sticker-list.vue';
import { getuploadspace } from '@/common/memory';
import { Loading } from 'element-ui';

export default {
  name: 'modelBase',
  components: {
    CartControll,
    StickerList,
    // ArrList,
    Category,
  },
  data() {
    let that = this;
    return {
      id: 0,
      activeName: 'model',
      // 空间ID
      tabGps: 0,
      // 空间数据
      getuploadspaceData: [],
      modelOtherData: {
        current_page: 1, // 当前页
        last_page: 0, // 总页数
        per_page: 10, // 每页条数
        total: 0, // 总数据条数
      },
      events: {
        selectAllEvent(data) {
          // const {id} = data;
          if (data.children && data.children.length > 0) {
            this.children = data.children;
            this.level = data.level;
            that.isShow = false;
          } else {
            this.level = data.level - 1;
            this.children = data.children;
            that.isShow = true;
          }
          if (data.id == 0) {
            that.isShow = false;
          } else if (data.show == 0) {
            that.isShow = false;
          }
          that.id = data.id;
          that.lastClassifyId = data.id;
        },
        selectSingleEvent(data) {
          // const {id} = data;
          if (data.children && data.children.length > 0) {
            this.children = data.children;
            this.level = data.level;
            that.isShow = false;
          } else {
            this.level = data.level - 1;
            this.children = data.children;
            that.isShow = true;
          }
          if (data.id == 0) {
            that.isShow = false;
          } else if (data.show == 0) {
            that.isShow = false;
          }

          that.id = data.id;
          that.lastClassifyId = data.id;
        },
      },
      isShow: false,
      input: '',
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      arr: {
        value: 'name',
        label: 'name',
        children: 'children',
      },
      checkedIDOne: '',
      checkedIDTwo: '',
      checkedIDThree: '',
      // options: [{
      //   value: '选项1',
      //   label: '黄金糕'
      // }, {
      //   value: '选项2',
      //   label: '双皮奶'
      // }, {
      //   value: '选项3',
      //   label: '蚵仔煎'
      // }, {
      //   value: '选项4',
      //   label: '龙须面'
      // }, {
      //   value: '选项5',
      //   label: '北京烤鸭'
      // }],
      value: '',
      chartletListArr: [],
      // chartletListArrEdit: [],
      classify_id: '',
      lastClassifyId: '',
      backupsID: '',
      modelData: [],
      titleBreadcrumb: '/所有',
      page: {
        page: 0,
        pageSize: 200000,
      },
      // 层级
      level: '',
      mapImgData: {
        current_page: 1,
        data: [],
        last_page: 0,
        per_page: 10,
        total: 0,
      },
    };
  },
  watch: {
    id: function (newValue, oldValue) {
      console.log(`-------- 组件监听到ID发生变化，oldValue: ${oldValue}，newValue: ${newValue}`);
      this.modelInit();
    },
  },
  computed: {
    noMore() {
      return this.count >= 20;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  async mounted() {
    await this.init();
    // 公共BUS 监听头部列表
    // Bus.$on('getTarget', (data, isShow, level) => {
    //   this.chartletList(data.id)
    //   this.isShow = isShow
    //   this.level = level
    // });.
  },
  methods: {
    modelNextPage(classify_id = 0) {
      if (this.modelOtherData.current_page >= this.modelOtherData.last_page) {
        // this.$message.warning('已经是最后一页了');
        console.log(
          `-------- 已经请求到最后一页了，current_page: ${this.modelOtherData.current_page}，last_page: ${this.modelOtherData.last_page}`,
        );
        return;
      }
      this.modelOtherData.current_page++;
      this.loadModelData(classify_id ? classify_id : this.id);
    },
    modelInit() {
      console.log('-------- 贴图子组件初始化');
      this.modelOtherData.current_page = 1;
      this.loadModelData();
    },
    loadModelData(classify_id) {
      const loadingInstance = Loading.service({
        target: '.headerModel',
        lock: true,
        text: '加载模型中...',
      });
      if (this.modelOtherData.current_page <= 1) {
        this.modelData = [];
      }

      const postData = {
        classify_id: classify_id > 0 ? classify_id : this.id,
        space_id: this.tabGps,
        page: this.modelOtherData.current_page,
        limit: this.modelOtherData.per_page,
      };

      this.backupsID = classify_id;

      model_baseIndex(postData).then((res) => {
        if (res.code !== 200) {
          console.log('贴图子组件请求出错', res);
          return;
        }

        if (!res.result.info.length && res.result.info.length === 0) {
          return;
        }

        this.modelOtherData.current_page = res.result.current_page;
        this.modelOtherData.last_page = res.result.last_page;
        this.modelOtherData.per_page = res.result.per_page;
        this.modelOtherData.total = res.result.total;

        let _this = this;
        res.result.info.forEach((e) => {
          this.modelData.push({
            id: {
              model_id: e.id,
              classify_id: _this.lastClassifyId,
              backupsID: _this.backupsID,
            },
            homeData: {
              icon: e.modelIcon,
              img: e.mappings,
              model: e.modelFile,
              name: e.modelName,
              num1: e.modelNumber,
              title: e.modelMaterial,
              num2: e.materialNumber,
              state: e.status,
              id: e.id, //模型ID
              stateValue: e.status_value,
            },
            // 基础信息
            basicsList: {
              productName: '',
              productNum: '',
              textureName: '',
              textureNum: '',
              space_id: this.tabGps,
            },
            // 规格信息
            specification: [
              {
                inputLenght: '',
                inputWidth: '',
                inputHeight: '',
                inputHolder: '',
                inputAerialPole: '',
                inputMin: '',
                inputMoney: '',
                inputBrokerage: '',
                inputRepertory: '',
                inputRepertoryNum: '',
                inputPeriod: '',
              },
            ],
            // 视频上传
            video: {
              id: '',
              installationVideo: [],
              cadDrawing: [],
              physicalPicture: [],
              architectureDiagram: [],
            },
            // 备注信息
            remark: {
              input1: '',
              input2: '',
              input3: '',
              input4: '',
              input5: '',
              input6: '',
              input7: '',
            },
            classify: '', //分类ID
            // 多选
            checkList: [],
            // 关联弹出框
            dialogVisible: false,
            // 贴图弹出框
            dialogVisible1: false,
            // 编辑按钮
            tabEdit: 1,
            // 状态
            state: 1,
            // icon,贴图，模型切换
            tableS: 1,
            // 横向列表
            tabs: 'basics',
            // 模型
            modelUrl: '',
            // icon
            iconImg: '',
            // 贴图
            chartletImg: [],
          });
        });
      });
      this.$nextTick(() => {
        loadingInstance.close();
      });
      this.$loading.close;
    },
    loadAllChartletListArr() {
      // if (this.chartletListArr.length > 0) {
      //     return;
      // }
      let dt = {
        is_all: 1,
        classify_id: 0,
        space_id: this.tabGps,
        limit: 9999999,
      };
      getmappinglist(dt).then((res) => {
        // console.log(res);
        if (res.code === 200) {
          this.chartletListArr = [];
          if (res.result.data.length && res.result.data.length > 0) {
            res.result.data.forEach((rs) => {
              this.chartletListArr.push({
                imageUrl: rs.whole_picture,
                input1: rs.title,
                input2: rs.number,
                publicBrandName: rs.publicBrandName,
                publicSupplyChainName: rs.publicSupplyChainName,
                // image: this.getImageFileFromUrl(rs.whole_picture, rs.title),
                image: '',
                id: rs.id,
              });
            });
          }
        }
      });
    },
    // 切换公库
    tabComponent(item) {
      this.tabGps = item;
      console.log(this.tabGps);
      // this.modelInit();
      // this.initData()
      // // this.ModelData()
      // this.$refs['sticker_list'].init();
      this.chartletList(this.lastClassifyId);
    },
    // 禁止编辑
    ProhibitToEdit() {
      this.$message.warning('请选择分类');
    },
    // 新增模型
    addModel() {
      this.modelData.unshift({
        homeData: {
          icon: '',
          img: '',
          model: '',
          name: '',
          num1: '',
          title: '',
          num2: '',
          state: '',
        },
        // 基础信息
        basicsList: {
          productName: '',
          productNum: '',
          textureName: '',
          textureNum: '',
          space_id: this.tabGps,
        },
        // 规格信息
        specification: [
          {
            specification: {
              inputLenght: '',
              inputWidth: '',
              inputHeight: '',
            },
            minimumShipment: '',
            factoryPrice: '',
            commission: '',
            inStock: {
              is: '',
              inputRepertoryNum: '',
            },
            arrivalPeriod: '',
            bracketVariable: {
              inputMax: '',
              inputMin: '',
            },
            OverheadPanel: '',
            cutSize: '',
            color: '',
            widthVariable: {
              inputMax: '',
              inputMin: '',
            },
            highVariable: {
              inputMax: '',
              inputMin: '',
            },
            style: '',
            minCutSize: {
              one: '',
              two: '',
              three: '',
            },
            power: '',
            doorOpeningDirection: '',
            placementMode: '',
            functionalHardware: {
              is: '',
              name: '',
            },
            openingSize: '',
            colorTemperature: '',
            illumination: '',
            diopter: '',
            mapping: [],
            minstretch: {
              length: '',
              width: '',
              height: '',
            },
            maxstretch: {
              length: '',
              width: '',
              height: '',
            },
          },
        ],
        // 视频上传
        video: {
          input1: '',
          input2: '',
          input3: '',
          input4: '',
        },
        // 备注信息
        remark: {
          input1: '',
          input2: '',
          input3: '',
          input4: '',
          input5: '',
          input6: '',
          input7: '',
        },
        // 多选
        checkList: [],
        // 关联弹出框
        dialogVisible: false,
        // 贴图弹出框
        dialogVisible1: false,
        // 编辑按钮
        tabEdit: 2,
        // 状态
        state: 1,
        // icon,贴图，模型切换
        tableS: 1,
        // 横向列表
        tabs: 'basics',
        // 模型
        modelUrl: '',
        // icon
        iconImg: '',
        // 贴图
        chartletImg: [],
        id: {
          // 分类id
          classify_id: this.lastClassifyId,
          // 模型id
          model_id: '',
          backupsID: this.backupsID,
        },
      });
      getconfiglist({ classify_id: this.lastClassifyId }).then((res) => {
        console.log(res.result);
        this.$refs.CartControllArr.vrayArr = res.result;
      });
    },
    // 新增贴图
    addChartlet() {
      // this.chartletListArrEdit.unshift({
      //   imageUrl: '',
      //   input1: '',
      //   input2: '',
      //   image: '',
      //   id: '',
      // })
      this.chartletListArr.unshift({
        imageUrl: '',
        input1: '',
        input2: '',
        publicBrandName: '',
        publicSupplyChainName: '',
        image: '',
        id: '',
      });
    },
    // 删除贴图
    deleteChartlet(item, index) {
      if (item.id) {
        let dt = {
          del_id: [item.id],
          del_url: [item.imageUrl],
        };
        setmappingdel(dt).then((res) => {
          let { code, msg } = res;
          if (code == 200) {
            this.chartletListArr.splice(index, 1);
            this.$message.success(msg);
          }
        });
      } else {
        // console.log(this.chartletListArr);
        // console.log(item);
        // this.chartletListArrEdit.forEach((element, ind) => {
        //   if (element.imageUrl == item.imageUrl) {
        //     if (element.input1 == item.input1) {
        //       if (element.input2 == item.input2) {
        //         // console.log(ind);
        //         // console.log(this.chartletListArrEdit);
        //         // this.chartletListArrEdit.splice(ind, 1);
        //       }
        //     }
        //   }
        // });

        // this.chartletListArrEdit.splice(index, 1);
        // console.log(this.chartletListArrEdit);
        this.chartletListArr.splice(index, 1);
        // console.log(this.chartletListArr);
        // this.$message.success('')
      }
    },
    // 修改贴图分类后重新渲染页面
    refresh(index) {
      if (this.lastClassifyId != 0) {
        this.chartletListArr.splice(index, 1);
      }
    },
    // 清空未保存
    deleteChartletList() {
      //filter数组过滤器，返回一个数组
      this.chartletListArr = this.chartletListArr.filter((e) => {
        return e.id != '';
      });
      // this.chartletListArrEdit = []
      // console.log(this.chartletListArr);
      this.$message.success('清空成功');
    },

    async init() {
      let res = await getuploadspace();
      let { code, result } = res;
      if (code == 200) {
        this.getuploadspaceData = result;
        //TODO 这里很重要
        // this.tabGps = result[0].id;

        this.tabGps = 0;
        for (let o of result) {
          //先选公库
          if (o.type == 1) {
            this.tabGps = o.id;
            break;
          }
        }

        if (this.tabGps == 0) {
          //再选选私库
          for (let o of result) {
            if (o.type == 2) {
              this.tabGps = o.id;
              break;
            }
          }
        }
        // this.chartletList(0) // 内部可查模型
        modelsConfig_list().then((res) => {
          if (res.code == 200) {
            this.data = res.result.config_list;
            this.data.splice(0, 0, {
              name: '所有体系',
              show: 0,
              id: 0,
              level: 1,
              children: [],
            });
            this.data.forEach((e) => {
              this.addAll(e);
            });
            // this.page.page = 1
          }
        });
      }
    },
    // 在每个数组前添加所有字段
    addAll(e) {
      if (e.children != '') {
        e.children.splice(0, 0, {
          name: '所有分类',
          show: 0,
          id: e.id,
          level: e.level + 1,
          children: [],
        });
        e.children.forEach((el) => {
          this.addAll(el);
        });
      } else {
        return;
      }
    },
    // 头部面包屑
    // Breadcrumb(childrenList, classData, inde) {
    //   // console.log(childrenList);
    //   // console.log(classData);
    //   // console.log(inde);
    //   childrenList.forEach((ele, index) => {
    //     // console.log(ele.level);
    //     if (ele.id == classData) {
    //       this.titleBreadcrumb = this.titleBreadcrumb + '/' + ele.name
    //       // if (ele.level > 1) {
    //       //   console.log(ele);
    //       //   console.log(childrenList[index]);
    //       // }
    //       // inde++
    //     } else {
    //       // console.log('我在else');
    //       this.Breadcrumb(ele.children, classData, inde)
    //     }
    //   });
    // },
    // 切换列表
    chartletList(classData) {
      // var inde = 0
      this.titleBreadcrumb = '';
      // this.Breadcrumb(this.data, classData, inde)

      this.lastClassifyId = classData;
      let dt = {
        is_all: this.lastClassifyId == 0 || this.lastClassifyId == undefined ? 1 : 0,
        classify_id: this.lastClassifyId,
        space_id: this.tabGps,
      };
      console.log(dt);
      // FIX 部分loading
      const loadingInstance = Loading.service({
        target: '.headerChartlet-body',
        lock: true,
        text: '加载花色材质贴图中...',
        fullscreen: false,
      });
      getmappinglist(dt).then((res) => {
        if (res.code == 200) {
          this.chartletListArr = [];
          if (res.result && res.result.data.length > 0) {
            console.log(res);

            res.result.data.forEach((rs) => {
              this.chartletListArr.push({
                imageUrl: rs.whole_picture,
                input1: rs.title,
                input2: rs.number,
                publicBrandName: rs.publicBrandName,
                publicSupplyChainName: rs.publicSupplyChainName,
                // image: this.getImageFileFromUrl(rs.whole_picture, rs.title),
                image: '',
                id: rs.id,
              });
            });
          }
          this.$forceUpdate();
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close();
          });
        }
        // this.page.page = 1
        if (classData == 0) {
          this.ModelData('all');
        } else {
          this.ModelData(this.lastClassifyId);
        }
      });
    },
    // url转换成file文件
    getImageFileFromUrl(url, imageName) {
      return new Promise((resolve, reject) => {
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.setRequestHeader('Accept', 'image/jpeg');
        xhr.responseType = 'blob';
        xhr.onload = () => {
          blob = xhr.response;
          let imgFile = new File([blob], imageName, { type: 'image/jpeg' });
          resolve(imgFile);
        };
        xhr.onerror = (e) => {
          reject(e);
        };
        xhr.send();
      });
    },
    // 模型列表
    ModelData(e) {
      this.loadModelData(e);
      // return;
      // 禁止查全部 对接口和页面压力过大
      // if (e == 'all') {
      //   this.$message.warning({
      //     message: '请细化体系分类！查询全部分类下模型，对接口和页面压力过大',
      //     duration: 5000,
      //     forbidClick: true,
      //   })
      //   })
      //   return
      // }
    },
  },
};
</script>

<style>
.el-tabs--border-card > .el-tabs__content {
  padding: 0 15px;
}
</style>
<style scoped lang="scss">
.btn {
  width: 109px;
  font-size: 14px;
  font-weight: 500;
  background-color: #ffffff;
  border: 1px solid #707070;
  padding: 6px 0px;
  text-align: center;
  cursor: pointer;
}

.btn:hover {
  background-color: #29303e;
  color: white;
}

.hello {
  background-color: white;
  padding-bottom: 20px;

  .header {
    margin: 0 20px;
    display: flex;
    flex-direction: column;

    .header-name {
      display: flex;
      align-items: center;
      // border-bottom: 0.5px solid #bbb;

      .header-name-left {
        font-weight: 800;
        margin-right: 20px;
        font-size: 20px;
        // line-height: 28px;
        color: #0c0c0c;
        opacity: 1;
      }

      .header-name-right {
        font-size: 12px;
      }
    }

    .headerList {
      overflow-x: scroll;
      margin: 30px auto 20px;
      display: block;
      // .headerList-one,
      // .headerList-two,
      // .headerList-three {
      //   font-size: 13px;
      //   margin: 10px 0;

      //   .spn1,
      //   .spn2,
      //   .spn3 {
      //     display: inline-block;
      //     padding: 5px 10px;
      //     cursor: pointer;
      //   }
      // }
    }

    .headerChartlet {
      margin-top: 20px;
      .headerChartlet-body {
        // padding: 10px;
        // box-shadow: 0 0 5px 3px rgb(231, 231, 231);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .headerChartlet-body-left {
          display: flex;

          .headerChartlet-body-left-left {
            // border: 2px dashed #bbb;
            border: 1px dashed transparent;
            background: linear-gradient(#fff, #fff) padding-box,
              repeating-linear-gradient(-45deg, #bbb 0, #bbb 0.3em, #fff 0, #fff 0.6em);
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            width: 180px;
            height: 270px;
            // min-height: 290px;
            // height: 270px;
            // background: rgba(255, 255, 255, 0.3900);
            // border: 1px solid #D5D5D5;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
            opacity: 1;
            color: #bbbbbb;
            margin-right: 14px;
            margin-top: 5px;
          }

          .el-icon-plus {
            margin: 102px auto 0;
          }
        }

        .headerChartlet-body-right {
          border-left: 1px solid #bbb;
          width: 200px;
          text-align: center;
        }
      }
    }

    .headerModel {
      .headerModer-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .headerModel-header-right {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 370px;
        }
      }

      .headerModel-body {
        display: flex;
        width: 100%;

        .headerModel-body-new {
          border: 1px dashed transparent;
          background: linear-gradient(#fff, #fff) padding-box,
            repeating-linear-gradient(-45deg, #bbb 0, #bbb 0.3em, #fff 0, #fff 0.6em);
          display: block;
          width: 254px;
          height: 440px;
          text-align: center;
          font-size: 13px;
          color: rgb(136, 136, 136);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
          cursor: pointer;
        }

        .el-icon-plus {
          margin: 164px auto 0;
        }
      }
    }
  }

  .BGColor {
    color: #0099ff;
    background-color: #dbdbdb;
    border-radius: 20px;
  }

  .subClassTitle {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #0c0c0c;
    opacity: 1;
    // border-bottom: 1px solid #D5D5D5;
    width: 100%;
    // margin: 20px 0;
    margin: 0;
  }
}

.gpsBtn {
  margin: 0 10px 0 0;
  font-size: 12px;
  .publicItem {
    font-weight: bold;
  }
  .one,
  .publicItem {
    cursor: pointer;
    margin: 0 10px 0 0;
    // background-color: #EEEEEE;
    background-color: white;
    border: 1px solid #29303e;
    padding: 4px 15px;
    border-radius: 5px;

    .item {
      font-size: 12px;
      color: #a6a6a6;
      padding-left: 5px;
    }
  }
}
</style>
