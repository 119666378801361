<template>
  <div>
    <el-form
      v-if="vrayArr"
      :model="basicsList.basicsList"
      :rules="rules.basicsList"
      ref="ruleForm"
      class="demo-ruleForm"
    >
      <el-form-item
        v-if="vrayArr.modelName"
        :label="vrayArr.modelName.title"
        prop="productName"
        :rules="[
          {
            required: vrayArr.modelName.is_must == 1,
            message: `请输入${vrayArr.modelName.title}`,
            trigger: 'blur',
          },
        ]"
      >
        <el-input size="mini" v-model="basicsList.basicsList.productName"></el-input>
      </el-form-item>
      <el-form-item
        v-if="vrayArr.modelNumber"
        :label="vrayArr.modelNumber.title"
        prop="productNum"
        :rules="[
          {
            required: vrayArr.modelNumber.is_must == 1,
            message: `请输入${vrayArr.modelNumber.title}`,
            trigger: 'blur',
          },
        ]"
      >
        <el-input size="mini" v-model="basicsList.basicsList.productNum"></el-input>
      </el-form-item>
      <el-form-item
        v-if="vrayArr.modelMaterial"
        :label="vrayArr.modelMaterial.title"
        prop="textureName"
        :rules="[
          {
            required: vrayArr.modelMaterial.is_must == 1,
            message: `请输入${vrayArr.modelMaterial.title}`,
            trigger: 'blur',
          },
        ]"
      >
        <el-input size="mini" v-model="basicsList.basicsList.textureName"></el-input>
      </el-form-item>

      <el-form-item
        v-if="vrayArr.constructionNumber"
        :label="vrayArr.constructionNumber.title"
        prop="constructionNumber"
        :rules="[
          {
            required: vrayArr.constructionNumber.is_must == 1,
            message: `请输入${vrayArr.constructionNumber.title}`,
            trigger: 'blur',
          },
        ]"
      >
        <el-input size="mini" v-model="basicsList.basicsList.constructionNumber"></el-input>
      </el-form-item>

      <el-form-item
        v-if="vrayArr.materialNumber"
        :label="vrayArr.materialNumber.title"
        prop="textureNum"
        :rules="[
          {
            required: vrayArr.materialNumber.is_must == 1,
            message: `请输入${vrayArr.materialNumber.title}`,
            trigger: 'blur',
          },
        ]"
      >
        <el-input size="mini" v-model="basicsList.basicsList.textureNum"></el-input>
      </el-form-item>
      <el-form-item
        v-if="vrayArr.publicBrandName"
        :label="vrayArr.publicBrandName.title"
        prop="publicBrandName"
        :rules="[
          {
            required: vrayArr.publicBrandName.is_must == 1,
            message: `请输入${vrayArr.publicBrandName.title}`,
            trigger: 'blur',
          },
        ]"
      >
        <el-input size="mini" v-model="basicsList.basicsList.publicBrandName"></el-input>
      </el-form-item>
      <el-form-item
        v-if="vrayArr.publicSupplyChainName"
        :label="vrayArr.publicSupplyChainName.title"
        prop="publicSupplyChainName"
        :rules="[
          {
            required: vrayArr.publicSupplyChainName.is_must == 1,
            message: `请输入${vrayArr.publicSupplyChainName.title}`,
            trigger: 'blur',
          },
        ]"
      >
        <el-input size="mini" v-model="basicsList.basicsList.publicSupplyChainName"></el-input>
      </el-form-item>
      <el-form-item
        v-if="vrayArr.heightFromGround"
        :label="vrayArr.heightFromGround.title"
        prop="heightFromGround"
        :rules="[
          {
            required: vrayArr.heightFromGround.is_must == 1,
            message: `请输入${vrayArr.heightFromGround.title}`,
            trigger: 'blur',
          },
        ]"
      >
        <el-input size="mini" v-model="basicsList.basicsList.heightFromGround"></el-input>
      </el-form-item>
      <!-- <el-form-item label="位置" prop="space_id">
                <el-select size="mini" v-model="basicsList.basicsList.space_id" placeholder="请选择" style="width: 100%;">
                    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item> -->
      <el-form-item>
        <el-button type="primary" size="mini" @click="submitForm('ruleForm', basicsList)">
          下一步
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { model_baseStep1, model_baseEdit_step1 } from '@/common/modelList';
import { getuploadspace } from '@/common/memory';
export default {
  name: 'basics',
  props: {
    basicsList: {
      type: Object,
    },
    vrayArr: {
      type: Object,
    },
  },
  data() {
    return {
      tabS: 0,
      rules: {
        basicsList: {
          // 基础信息规则
          // productName: [
          //     { required: true, message: '请填写产品名称', trigger: 'blur' }
          // ],
          // productNum: [
          //     { required: true, message: '请填写产品编号', trigger: 'blur' }
          // ],
          // textureName: [
          //     { required: true, message: '请填写材质名称', trigger: 'blur' }
          // ],
          // textureNum: [
          //     { required: true, message: '请填写材质编号', trigger: 'blur' }
          // ],
          // space_id: [
          //     { required: true, message: '请选择位置', trigger: ['blur', 'change'] }
          // ],
        },
      },
      options: [],
    };
  },
  created() {
    getuploadspace().then((res) => {
      let { code, result } = res;
      if (code == 200) {
        this.options = result;
      }
    });
  },
  methods: {
    submitForm(formName, basicsList) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (basicsList.basicsList.id) {
            let dt = {
              model_id: basicsList.basicsList.id,
              modelName: basicsList.basicsList.productName,
              modelNumber: basicsList.basicsList.productNum,
              modelMaterial: basicsList.basicsList.textureName,
              constructionNumber: basicsList.basicsList.constructionNumber,
              materialNumber: basicsList.basicsList.textureNum,
              space_id: basicsList.basicsList.space_id,
              publicBrandName: basicsList.basicsList.publicBrandName,
              publicSupplyChainName: basicsList.basicsList.publicSupplyChainName,
              heightFromGround: basicsList.basicsList.heightFromGround,
            };
            model_baseEdit_step1(dt).then((res) => {
              let { code, msg } = res;
              if (code == 200) {
                this.$message.success(msg);
                this.$emit('tab');
              }
            });
          } else {
            let dt = {
              classify_id: basicsList.id.classify_id, //分类ID
              modelName: basicsList.basicsList.productName,
              modelNumber: basicsList.basicsList.productNum,
              modelMaterial: basicsList.basicsList.textureName,
              materialNumber: basicsList.basicsList.textureNum,
              constructionNumber: basicsList.basicsList.constructionNumber,
              space_id: basicsList.basicsList.space_id,
              publicBrandName: basicsList.basicsList.publicBrandName,
              publicSupplyChainName: basicsList.basicsList.publicSupplyChainName,
              heightFromGround: basicsList.basicsList.heightFromGround,
            };
            model_baseStep1(dt).then((res) => {
              let { code, msg, result } = res;
              if (code == 200) {
                basicsList.id.model_id = result.model_id;
                this.$message.success(msg);
                this.$emit('tab');
              }
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button {
  width: 100%;
  margin: 0;
}
</style>
