<template>
  <div>
    <!-- <div class="body" style="display: flex;width: 100%;overflow-x: scroll;padding: 0 0 5px 5px;"
            id="eagleMapContainer" @scroll="hanldeScroll"> -->
    <div
      class="body"
      style="display: flex; width: 100%; overflow-x: scroll; padding: 0 0 5px 5px"
      @scroll="scrollChange"
    >
      <!-- <div class="headerModel-body-new" @click="$emit('addModel')">
                <div style="width: 290px;">
                    <i class="el-icon-plus"></i><br>
                    <p>点击新增模型</p>
                </div>
            </div> -->
      <div
        class="modelImg"
        v-for="(itemModel, indexS) in modelData"
        :key="indexS"
        style="margin-right: 10px; margin-top: 10px"
      >
        <!-- <span style="font-size: 14px;">
                    {{ itemModel.id }}
                </span> -->
        <div>
          <div v-if="itemModel.tabEdit == 1">
            <div class="bodyMX">
              <div class="modelImgOne">
                <p v-if="itemModel.tableS != 1" @click="itemModel.tableS = 1">icon</p>
                <p v-if="itemModel.tableS != 2" @click="itemModel.tableS = 2">贴图</p>
                <p v-if="itemModel.tableS != 3" @click="itemModel.tableS = 3">模型</p>
              </div>
              <div class="delda" v-if="tabGps != 0">
                <p @click="delModel(itemModel)">删除</p>
                <p @click="chanegClassification(itemModel)">修改分类</p>
                <p v-if="itemModel.homeData.stateValue == 1" @click="forbidden(itemModel)">禁用</p>
                <p v-if="itemModel.homeData.stateValue == 0" @click="forbidden(itemModel)">启用</p>
              </div>
              <div v-if="itemModel.tableS == 3" style="width: 290px; height: 200px">
                <three-view
                  class="three-box"
                  :sceneUrl="sceneUrl"
                  :modelUrl="itemModel.homeData.model"
                  :autoRotate="false"
                />
              </div>
              <div v-if="itemModel.tableS == 2" style="width: 290px; height: 200px">
                <div v-if="itemModel.homeData.img.specs_1">
                  <div v-for="(ite, index) in itemModel.homeData.img.specs_1" :key="index">
                    <img
                      style="width: 290px; height: 200px; object-fit: cover"
                      v-if="index == 0"
                      :src="ite.mapping_pictures"
                      alt=""
                    />
                    <!-- <el-image style="width: 290px; height: 200px"
                                        :src="itemModel.homeData.img.specs_1[0].mapping_pictures"
                                        :preview-src-list="[itemModel.homeData.img.specs_1[0].mapping_pictures]">
                                    </el-image> -->
                  </div>
                </div>
              </div>
              <div v-if="itemModel.tableS == 1" style="width: 290px; height: 200px">
                <!-- <img style="width: 290px;height: 200px;" :src="itemModel.homeData.icon" alt=""> -->
                <el-image
                  v-if="itemModel.homeData.icon"
                  style="width: 290px; height: 200px"
                  :src="itemModel.homeData.icon"
                  fit="cover"
                  :preview-src-list="[itemModel.homeData.icon]"
                ></el-image>

                <el-empty :image-size="100" v-else description="未上传 Icon"></el-empty>
              </div>
            </div>
            <div style="margin: 10px 15px; font-size: 15px">
              <div style="display: flex; align-items: center; justify-content: space-between">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="itemModel.homeData.name"
                  placement="top-start"
                >
                  <h4
                    style="
                      width: calc(100% - 75px);
                      display: block;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    {{ itemModel.homeData.name }}
                  </h4>
                </el-tooltip>
                <!-- <h4>{{ itemModel.homeData.state }}</h4> -->
                <span
                  v-if="itemModel.homeData.state == '使用中'"
                  class="state"
                  style="
                    border: 1px solid #409eff;
                    color: #409eff;
                    background-color: #ecf5ff;
                    padding: 5px 10px;
                  "
                >
                  使用中
                </span>
                <span
                  v-if="itemModel.homeData.state == '审核中'"
                  class="state"
                  style="
                    border: 1px solid #67c23a;
                    color: #67c23a;
                    background-color: #f0f9ec;
                    padding: 5px 10px;
                  "
                >
                  审核中
                </span>
                <span
                  v-if="itemModel.homeData.state == '被驳回'"
                  class="state"
                  style="
                    border: 1px solid #e6a23c;
                    color: #e6a23c;
                    background-color: #fdf6ec;
                    padding: 5px 10px;
                  "
                >
                  被驳回
                </span>
                <span
                  v-if="itemModel.homeData.state == '停用中'"
                  class="state"
                  style="
                    border: 1px solid #909399;
                    color: #909399;
                    background-color: #f4f4f5;
                    padding: 5px 10px;
                  "
                >
                  停用中
                </span>
                <span
                  v-if="itemModel.homeData.state == '已下线'"
                  class="state"
                  style="
                    border: 1px solid #f56c6c;
                    color: #f56c6c;
                    background-color: #fef1f1;
                    padding: 5px 10px;
                  "
                >
                  已下线
                </span>
                <span
                  v-if="itemModel.homeData.state == '编辑中'"
                  class="state"
                  style="
                    border: 1px solid #2e3237;
                    color: #2e3237;
                    background-color: #d0d0d0;
                    padding: 5px 10px;
                  "
                >
                  编辑中
                </span>
              </div>
              <p class="hiddenText">
                <span class="textTitle">产品编号：</span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="itemModel.homeData.num1"
                  placement="top-start"
                >
                  <span class="textBody">
                    {{ itemModel.homeData.num1 }}
                  </span>
                </el-tooltip>
              </p>
              <p class="hiddenText">
                <span class="textTitle">材质名称：</span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="itemModel.homeData.title"
                  placement="top-start"
                >
                  <span class="textBody">
                    {{ itemModel.homeData.title }}
                  </span>
                </el-tooltip>
              </p>
              <p class="hiddenText">
                <span class="textTitle">材质编号：</span>
                <el-tooltip
                  v-if="itemModel.homeData.num2"
                  class="item"
                  effect="dark"
                  :content="itemModel.homeData.num2"
                  placement="top-start"
                >
                  <span class="textBody">
                    {{ itemModel.homeData.num2 }}
                  </span>
                </el-tooltip>
              </p>
              <el-button v-if="tabGps != 0" type="info" size="small" @click="edit(itemModel)" plain>
                编辑
              </el-button>
            </div>
          </div>
          <div v-else class="modelImg">
            <div class="modelImgZero">
              <div
                class="isSss"
                v-if="itemModel.tabEdit == 2 && !itemModel.id.model_id"
                style="
                  font-size: 15px;
                  position: absolute;
                  top: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  z-index: 99;
                  background-color: rgba(0, 0, 0, 0.5);
                  width: 100%;
                  text-align: center;
                "
              >
                <p style="margin: 10px 0; cursor: pointer" @click="delModelComponents(indexS)">
                  删除模型
                </p>
              </div>
              <!-- 模型 -->
              <div v-if="itemModel.tableS == 3" class="upload-demo">
                <el-upload
                  v-if="!itemModel.modelUrl"
                  drag
                  action="#"
                  :multiple="false"
                  :auto-upload="false"
                  :show-file-list="false"
                  :on-change="
                    (file) => {
                      handleAvatarSuccessModel(file, itemModel);
                    }
                  "
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    将模型拖到此处，或
                    <em>点击上传</em>
                  </div>
                </el-upload>
                <div style="width: 290px; height: 200px; position: relative" v-else>
                  <three-view
                    class="three-box"
                    :sceneUrl="sceneUrl"
                    :modelUrl="itemModel.modelUrl"
                    :autoRotate="false"
                  />
                  <div class="delModelCss">
                    <p @click="delModelGltf(itemModel)">删除模型</p>
                  </div>
                </div>
              </div>
              <!-- icon -->
              <div v-if="itemModel.tableS == 1">
                <el-upload
                  class="upload-demo"
                  v-if="itemModel.iconImg == '' || itemModel.iconImg == null"
                  drag
                  action="#"
                  :multiple="false"
                  :auto-upload="false"
                  :show-file-list="false"
                  :on-change="
                    (file) => {
                      handleAvatarSuccessIcon(file, itemModel);
                    }
                  "
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    将icon图拖到此处，或
                    <em>点击上传</em>
                  </div>
                </el-upload>
                <div v-else style="width: 290px; height: 200px; position: relative">
                  <!-- <img style="width: 290px;height: 200px;" :src="itemModel.iconImg" alt=""> -->
                  <el-image
                    style="width: 290px; height: 200px"
                    :src="itemModel.iconImg"
                    :preview-src-list="[itemModel.iconImg]"
                  ></el-image>
                  <div class="delModelCss">
                    <p @click="delIconGltf(itemModel)">删除icon</p>
                  </div>
                </div>
              </div>
              <!-- 贴图 -->
              <!-- <span style="font-size: 20px;background-color: white;">
                                {{ itemModel.specification }}
                            </span> -->
              <div v-if="itemModel.tableS == 2">
                <div v-if="itemModel.chartletImg != null">
                  <div v-if="itemModel.specification[guigeIndex]">
                    <el-carousel trigger=" click" height="200px" :autoplay="false" arrow="never">
                      <el-carousel-item
                        v-for="item in itemModel.specification[guigeIndex].mapping"
                        :key="item.id"
                      >
                        <div style="width: 290px; height: 200px">
                          <img
                            width="100%"
                            height="100%"
                            :src="item.mapping_pictures"
                            style="object-fit: cover"
                            @click="open(itemModel.specification[guigeIndex].mapping)"
                          />
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
                <div v-else>
                  <div
                    style="width: 290px; height: 200px; background-color: #f2f2f2; font-size: 20px"
                    class="upload-demo"
                  ></div>
                </div>
              </div>
              <!-- 右侧按钮 -->
              <div class="modelImgOne">
                <p v-if="itemModel.tableS != 1" @click="itemModel.tableS = 1">icon</p>
                <p v-if="itemModel.tableS != 2" @click="itemModel.tableS = 2">贴图</p>
                <p v-if="itemModel.tableS != 3" @click="itemModel.tableS = 3">模型</p>
              </div>
              <!-- <div v-if="itemModel.tableS == 2" class="modelImgtwo">
                                <p>删除贴图</p>
                            </div> -->
              <div v-if="itemModel.tableS == 2" class="modelImgfour">
                <p style="margin: 0" @click="relevancyImg(itemModel)">关联贴图</p>
              </div>
              <div>
                <el-dialog :visible.sync="dialogVisible" width="45%">
                  <div
                    slot="title"
                    class="dialog-footer"
                    style="display: flex; align-items: center; justify-content: right"
                  >
                    <span style="font-weight: bold">贴图库</span>
                    <el-input
                      v-model="filter"
                      size="mini"
                      style="width: 180px"
                      placeholder="请输入数据"
                    />
                    <span style="font-size: 12px; margin-right: 25px">
                      当前已关联
                      {{ itemModel.checkList ? itemModel.checkList.length : 0 }}
                      /{{ chartletListArr.length }}
                    </span>
                    <span style="font-size: 12px; margin-right: 20px">
                      <el-checkbox
                        v-model="checkAll"
                        :indeterminate="isIndeterminate"
                        @change="handleCheckAllChange(checkAll, indexS)"
                      >
                        <span style="margin-right: 20px">全选</span>
                      </el-checkbox>
                    </span>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: left;
                      flex-wrap: wrap;
                      height: 500px;
                      overflow-y: scroll;
                    "
                  >
                    <el-checkbox-group
                      v-model="itemModel.checkList"
                      @change="handleCheckedCitiesChange"
                    >
                      <el-checkbox
                        v-for="(ite, index) in filterMappers"
                        :key="index"
                        :label="processingData(ite)"
                      >
                        <div style="width: 150px; height: 150px">
                          <img
                            style="width: 100%; height: 100%; object-fit: cover"
                            :src="ite.imageUrl"
                          />
                        </div>
                        <p
                          style="
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 150px;
                          "
                        >
                          {{ ite.input1 }}
                        </p>
                        <p
                          style="
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 150px;
                          "
                        >
                          {{ ite.input2 }}
                        </p>
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <span
                    slot="footer"
                    class="dialog-footer"
                    style="display: flex; justify-content: center"
                  >
                    <el-button style="width: 150px" @click="dialogVisible = false">取 消</el-button>
                    <el-button
                      style="width: 150px"
                      type="primary"
                      @click="checkListArr(itemModel, `child${itemModel.homeData.id}`)"
                    >
                      保存本次所选
                    </el-button>
                  </span>
                </el-dialog>
              </div>
              <div v-if="itemModel.tableS == 2" class="modelImgfive">
                <div v-if="itemModel.specification[guigeIndex]">
                  <div v-if="itemModel.specification[guigeIndex].mapping != null">
                    <p>已关联{{ itemModel.specification[guigeIndex].mapping.length }}个贴图</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="modelImg-list" style="display: flex">
              <span
                :class="itemModel.tabs == 'basics' ? 'list tabColor' : 'list'"
                @click="itemModel.tabs = 'basics'"
              >
                基础信息
              </span>
              <!-- {{ itemModel.id.model_id }} -->
              <div style="display: inline-block" v-if="itemModel.id.model_id != ''">
                <span
                  :class="itemModel.tabs == 'specification' ? 'list tabColor' : 'list'"
                  @click="itemModel.tabs = 'specification'"
                >
                  规格信息
                </span>
                <span
                  :class="itemModel.tabs == 'file' ? 'list tabColor' : 'list'"
                  @click="itemModel.tabs = 'file'"
                >
                  文件上传
                </span>
                <span
                  :class="itemModel.tabs == 'remark' ? 'list tabColor' : 'list'"
                  @click="itemModel.tabs = 'remark'"
                >
                  备注信息
                </span>
              </div>
              <div v-else style="display: inline-block">
                <span :class="itemModel.tabs == 'specification' ? 'list tabColor' : 'list'">
                  规格信息
                </span>
                <span :class="itemModel.tabs == 'file' ? 'list tabColor' : 'list'">文件上传</span>
                <span :class="itemModel.tabs == 'remark' ? 'list tabColor' : 'list'">备注信息</span>
              </div>
            </div>
            <div class="modelData">
              <div v-if="itemModel.tabs == 'basics'">
                <basics :vrayArr="vrayArr.basic" :basicsList="itemModel" @tab="tab(itemModel)" />
              </div>
              <div v-if="itemModel.tabs == 'specification'">
                <specification
                  :specification="itemModel"
                  :ref="`child${itemModel.homeData.id}`"
                  @addSpecification="addSpecification(itemModel)"
                  @guigeImg="guigeImg"
                  :vrayArr="vrayArr.specificationinfo"
                  @tab="tab2(itemModel)"
                  :check="itemModel.checkList"
                />
              </div>
              <div v-if="itemModel.tabs == 'file'">
                <video-list
                  :video="itemModel"
                  @tab="tab3(itemModel)"
                  :vrayArr="vrayArr.file"
                  @editModelData="editModelData"
                />
              </div>
              <div v-if="itemModel.tabs == 'remark'">
                <remark :remark="itemModel" @tab="tab4(itemModel)" :vrayArr="vrayArr.remarks" />
              </div>
            </div>
          </div>
          <div class="zzz">
            <el-dialog :visible.sync="dialogVisible1" width="45%">
              <div style="position: relative">
                <div style="position: absolute; top: 20px; right: 0">
                  <!-- <span
                                        style="background-color:rgba(0, 0, 0, 0.5);color: white;padding: 10px 15px;margin-right: 10px;border-radius: 20px;cursor: pointer;"
                                        @click="relevancyImg(itemModel)">关联贴图</span> -->
                  <!-- <span
                                        style="background-color:rgba(0, 0, 0, 0.5);color: white;padding: 10px 15px;margin-right: 10px;border-radius: 20px;cursor: pointer;">取消关联</span>
                                    <span
                                        style="background-color:rgba(0, 0, 0, 0.5);color: white;padding: 10px 15px;margin-right: 10px;border-radius: 20px;cursor: pointer;">删除贴图</span> -->
                </div>
                <div style="height: 400px; text-align: center">
                  <img style="height: 100%; width: 100%; object-fit: cover" :src="openImg" alt="" />
                </div>
                <!-- <div
                                    style="position: absolute;bottom: 0;left: 0;background-color: rgba(0, 0, 0, 0.5);width: 100%;display: flex;justify-content: space-between;color:white;">
                                    <span style="padding: 10px;">
                                        贴图名称：圣伯雅子云系列XA245
                                    </span>
                                    <span style="padding: 10px;">当前5/15</span>
                                </div> -->
              </div>
              <div
                slot="footer"
                class="dialog-footer"
                style="display: flex; justify-content: center"
              >
                <img
                  v-for="it in openImgList"
                  :key="it"
                  style="width: 50px; height: 50px; margin: 0 3px; object-fit: cover"
                  :src="it"
                  @click="openImg = it"
                />
              </div>
            </el-dialog>
          </div>
        </div>
      </div>
      <!-- <p v-if="loading">加载中...</p> -->
      <!-- <p v-if="noMore">没有更多了</p> -->
    </div>
    <!-- 修改分类 -->
    <el-dialog
      title="修改模型分类"
      :visible.sync="alterClassificationDialog"
      width="450px"
      center
      :close-on-click-modal="false"
    >
      <el-cascader
        v-model="chartletInput"
        :options="options"
        :props="{ label: 'name', value: 'id' }"
        style="width: 400px"
      ></el-cascader>
      <span slot="footer" class="dialog-footer">
        <el-button @click="alterClassificationDialog = false" style="width: 100px">取 消</el-button>
        <el-button type="primary" @click="changeChartletClassification" style="width: 100px">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Specification from './modelComponent/specification.vue';
import Basics from './modelComponent/basics.vue';
import VideoList from './modelComponent/videoList.vue';
import Remark from './modelComponent/remark.vue';
import ThreeView from './three/three-view.vue';
import {
  modelsFileUpload,
  model_baseModel_details,
  delete_model,
  del_modelFile,
  getconfiglist,
  model_setstatus,
  modelsConfig_list,
  model_setclassify,
} from '@/common/modelList';
// const cityOptions = ['上海', '北京', '广州', '深圳'];
export default {
  name: 'CartControll',
  components: {
    Specification,
    Basics,
    VideoList,
    Remark,
    ThreeView,
  },
  props: {
    modelData: {
      type: Array,
      default: () => {},
    },
    tabGps: {
      type: Number,
      default: () => 0,
    },
    chartletListArr: {
      type: Array,
      default: () => {},
    },
  },
  computed: {
    filterMappers() {
      if (this.chartletListArr != undefined) {
        return this.chartletListArr.filter((item) => {
          if (!item || !item.input1 || !item.input2) { 
            return false;
          }
          return item.input1.includes(this.filter) | item.input2.includes(this.filter);
        });
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      filter: '',
      // loading: false,
      mappers: {},
      openImg: '',
      openImgList: [],
      // 贴图索引
      guigeIndex: 0,
      // 多选
      checkList: [],
      // 关联弹出框
      dialogVisible: false,
      // 贴图弹出框
      dialogVisible1: false,
      // 编辑按钮
      tabEdit: 1,
      // 状态
      state: 1,
      // icon,贴图，模型切换
      tableS: 3,
      // 横向列表
      tabs: 'basics',
      sceneUrl: 'file/QQ截图20220617112338.jpg',
      modelUrl: '',
      // 渲染数组
      vrayArr: {},
      // 全选
      checkAll: false,
      isIndeterminate: false,
      alterClassificationDialog: false,
      options: [],
      chartletInput: [],
      ModelID: '',
      classificationID: '',
    };
  },
  methods: {
    scrollChange(event) {
      let el = event.target;
      if (el.scrollLeft + el.clientWidth >= el.scrollWidth) {
        this.$emit('modelNextPage');

        // this.listData.current_page++;
        // this.loadData();
      }
    },
    // 全选
    handleCheckAllChange(val, index) {
      let data = [];
      if (this.chartletListArr) {
        this.chartletListArr.forEach((element) => {
          data.push(`id:${element.id}`);
          this.mappers[element.mapping] = element.imageUrl;
        });
        this.modelData[index].checkList = val ? data : [];
        this.isIndeterminate = false;
      }
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.chartletListArr.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.chartletListArr.length;
    },
    // 新增时删除模型
    delModelComponents(indexS) {
      this.modelData.splice(indexS, 1);
    },
    // 禁用按钮
    forbidden(e) {
      let dt = {
        model_id: e.id.model_id,
      };
      model_setstatus(dt).then((res) => {
        let { code, msg } = res;
        if (code == 200) {
          this.$emit('ModelData', e.id.classify_id);
          this.$message.success(msg);
        }
      });
    },
    //打开关联图
    open(e) {
      this.openImgList = [];
      this.dialogVisible1 = true;
      e.forEach((res) => {
        this.openImgList.push(res.mapping_pictures);
      });
      this.openImg = e[0].mapping_pictures;
    },
    // 规格切换贴图
    guigeImg(index) {
      this.guigeIndex = index;
    },
    //处理数据
    processingData(ite) {
      let data = `id:${ite.id}`;
      this.mappers[ite.id] = ite.imageUrl;

      return data;
    },
    // 删除模型
    delModel(itemModel) {
      this.$confirm('此操作将永久删除该模型, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let dt = {
            model_id: itemModel.homeData.id,
          };
          delete_model(dt).then((res) => {
            let { code, msg } = res;
            if (code == 200) {
              this.$emit('ModelData', itemModel.id.classify_id);
              this.$message.success(msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 修改模型分类对话框
    chanegClassification(itemModel) {
      this.chartletInput = '';
      this.ModelID = itemModel.homeData.id;
      this.classificationID = itemModel.id.classify_id;
      this.alterClassificationDialog = true;
      modelsConfig_list().then((res) => {
        let data = (arr) => {
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].children.length != 0) {
              data(arr[i].children);
            } else {
              arr[i].children = '';
            }
          }
        };
        data(res.result.config_list);
        this.options = res.result.config_list;
      });
    },
    // 修改模型分类
    changeChartletClassification() {
      let dt = {
        classify_id: this.chartletInput[this.chartletInput.length - 1],
        ids: [this.ModelID],
      };
      model_setclassify(dt).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.chartletInput = '';
          this.$emit('ModelData', this.classificationID);
          this.alterClassificationDialog = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 上传模型
    handleAvatarSuccessModel(file, itemModel) {
      this.image = file.raw;
      let dt = {
        model_id: itemModel.id.model_id,
        model_file: file.raw,
      };
      modelsFileUpload(dt).then((res) => {
        let { code, msg } = res;
        if (code == 200) {
          this.editModelData(itemModel);
          this.$message.success(msg);
        }
      });
    },
    // 上传icon
    handleAvatarSuccessIcon(file, itemModel) {
      let dt = {
        model_id: itemModel.id.model_id,
        model_icon: file.raw,
      };
      modelsFileUpload(dt).then((res) => {
        let { code, msg } = res;
        if (code == 200) {
          this.editModelData(itemModel);
          this.$message.success(msg);
        }
      });
    },
    // 添加规格信息
    addSpecification(item) {
      item.specification.push({
        specification: {
          inputLenght: '',
          inputWidth: '',
          inputHeight: '',
        },
        minimumShipment: '',
        factoryPrice: '',
        commission: '',
        inStock: {
          is: '',
          inputRepertoryNum: '',
        },
        arrivalPeriod: '',
        bracketVariable: {
          inputMax: '',
          inputMin: '',
        },
        OverheadPanel: '',
        cutSize: '',
        color: '',
        widthVariable: {
          inputMax: '',
          inputMin: '',
        },
        highVariable: {
          inputMax: '',
          inputMin: '',
        },
        style: '',
        minCutSize: {
          one: '',
          two: '',
          three: '',
        },
        power: '',
        doorOpeningDirection: '',
        placementMode: '',
        functionalHardware: {
          is: '',
          name: '',
        },
        openingSize: '',
        colorTemperature: '',
        illumination: '',
        diopter: '',
        mapping: [],
        mapping_pictures: [],
        minstretch: {
          length: '',
          width: '',
          height: '',
        },
        maxstretch: {
          length: '',
          width: '',
          height: '',
        },
      });
    },
    // 编辑
    edit(itemModel) {
      this.$nextTick(() => {
        this.editModelData(itemModel);
      });
      itemModel.tabEdit = 2;
      this.guigeIndex = 0;
    },
    // 模型详情
    editModelData(itemModel) {
      model_baseModel_details({ model_id: itemModel.id.model_id }).then((res) => {
        let at = res.result.info;
        // if (at) {
        // itemModel.id = {
        //     model_id: itemModel.id.model_id,
        //     classify_id: itemModel.id.classify_id,
        //     backupsID: 132456
        // }
        // 基础信息
        itemModel.basicsList = {
          id: at.id,
          productName: at.modelName,
          productNum: at.modelNumber,
          textureName: at.modelMaterial,
          textureNum: at.materialNumber,
          publicBrandName: at.publicBrandName,
          constructionNumber: at.constructionNumber,
          publicSupplyChainName: at.publicSupplyChainName,
          heightFromGround: at.heightFromGround,
          // space_id: at.space_id,
        };
        // 规格信息
        itemModel.specification = [];
        if (at.specification.length < 1) {
          itemModel.specification.push({
            id: at.id,
            specification: {
              inputLenght: '',
              inputWidth: '',
              inputHeight: '',
            },
            minimumShipment: '',
            factoryPrice: '',
            commission: '',
            inStock: {
              is: '',
              inputRepertoryNum: '',
            },
            arrivalPeriod: '',
            bracketVariable: {
              inputMax: '',
              inputMin: '',
            },
            OverheadPanel: '',
            cutSize: '',
            color: '',
            widthVariable: {
              inputMax: '',
              inputMin: '',
            },
            highVariable: {
              inputMax: '',
              inputMin: '',
            },
            style: '',
            minCutSize: {
              one: '',
              two: '',
              three: '',
            },
            power: '',
            doorOpeningDirection: '',
            placementMode: '',
            functionalHardware: {
              is: '',
              name: '',
            },
            openingSize: '',
            colorTemperature: '',
            illumination: '',
            diopter: '',
            mapping: [],
            mapping_pictures: [],
            minstretch: {
              length: '',
              width: '',
              height: '',
            },
            maxstretch: {
              length: '',
              width: '',
              height: '',
            },
          });
        } else {
          at.specification.forEach((el) => {
            if (!el.bracketVariable) {
              el.bracketVariable = {
                inputMax: '',
                inputMin: '',
              };
            }
            if (!el.minCutSize) {
              el.minCutSize = {
                one: '',
                two: '',
                three: '',
              };
            }
            if (!el.specification) {
              el.specification = {
                inputLenght: '',
                inputWidth: '',
                inputHeight: '',
              };
            }
            if (!el.inStock) {
              el.inStock = {
                is: '',
                inputRepertoryNum: '',
              };
            }
            if (!el.widthVariable) {
              el.widthVariable = {
                inputMax: '',
                inputMin: '',
              };
            }
            if (!el.highVariable) {
              el.highVariable = {
                inputMax: '',
                inputMin: '',
              };
            }
            if (!el.functionalHardware) {
              el.functionalHardware = {
                is: '',
                name: '',
              };
            }
            if (!el.minstretch) {
              el.minstretch = {
                length: '',
                width: '',
                height: '',
              };
            }
            if (!el.maxstretch) {
              el.maxstretch = {
                length: '',
                width: '',
                height: '',
              };
            }
            itemModel.specification.push(el);
          });
        }
        // if (at) {
        // 备注信息
        itemModel.remark = {
          id: itemModel.id.model_id, //模型ID
          classify: itemModel.id.classify_id, //分类ID
          input1: at.logistics == 1 ? '是' : '否',
          input2: at.isInstall == 1 ? '是' : '否',
          input3: at.afterSalesPolicy,
          input4: at.remarks,
          input5: at.installExplain,
          input6: at.features,
          input7: at.job_desc,
        };
        // 视频上传
        itemModel.video = {
          id: itemModel.id.model_id,
          installationVideo: at.installationVideo,
          cadDrawing: at.cadDrawing,
          physicalPicture: at.physicalPicture,
          architectureDiagram: at.architectureDiagram,
        };
        itemModel.modelUrl = at.modelFile;
        itemModel.iconImg = at.modelIcon;
        itemModel.chartletImg = at.specification;
        // 分类ID
        itemModel.classify = itemModel.id.classify_id;
        // 规格信息动态展示
        getconfiglist({ classify_id: at.classify }).then((res) => {
          this.vrayArr = res.result;
        });
        itemModel.id = {
          classify_id: at.classify,
          model_id: at.id,
          backupsID: itemModel.id.backupsID,
        };
      });
    },
    // 保存本次所选
    checkListArr(e, child) {
      let dataArr = [];
      if (e.checkList) {
        e.checkList.forEach((item) => {
          let obj = item.split(',');
          let a1 = obj[0].split('id:');
          // let a2 = obj[1].split('imageUrl:');
          dataArr.push({
            mapping: a1[1],
            mapping_pictures: this.mappers[a1[1]],
          });
        });
      }
      if (e.specification) {
        if (e.specification[this.guigeIndex]) {
          // if (e.specification[this.guigeIndex]['mapping']) {
          e.specification[this.guigeIndex]['mapping'] = dataArr;
          // }
        }
      }
      this.dialogVisible = false;
      let that = this;
      console.log(that.$refs);
      this.$nextTick(() => {
        that.$refs[child][0].submitFormNew();
      });
    },
    // 关联方法
    relevancyImg(data) {
      // 首次获取全部贴图
      this.$emit('loadAllChartletListArr');
      data.checkList = [];
      if (data.specification[this.guigeIndex]) {
        if (data.specification[this.guigeIndex].mapping) {
          data.specification[this.guigeIndex].mapping.forEach((res) => {
            data.checkList.push(`id:${res.mapping}`);
            this.mappers[res.mapping] = res.mapping_pictures;
          });
        }
      }
      this.checkAll = this.chartletListArr.length == data.checkList.length ? true : false;
      this.isIndeterminate =
        data.checkList.length > 0 && this.chartletListArr.length > data.checkList.length
          ? true
          : false;
      this.dialogVisible = true;
      this.filter = '';
    },
    tab(itemModel) {
      itemModel.tabs = 'specification';
    },
    tab2(itemModel) {
      itemModel.tabs = 'file';
    },
    tab3(itemModel) {
      itemModel.tabs = 'remark';
    },
    tab4(itemModel) {
      itemModel.tabEdit = 1;
      itemModel.tabs = 'basics';
      // this.$emit('ModelData', itemModel.id.classify_id)
      this.$emit('ModelData', itemModel.id.backupsID);
    },
    // 删除模型文件
    delModelGltf(item) {
      this.$confirm('此操作将永久删除该模型文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let dt = {
            model_id: item.basicsList.id,
            model_file: item.modelUrl,
          };
          del_modelFile(dt).then((res) => {
            let { code, msg } = res;
            if (code == 200) {
              this.editModelData(item);
              this.$message.success(msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 删除icon文件
    delIconGltf(item) {
      this.$confirm('此操作将永久删除该icon文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let dt = {
            model_id: item.basicsList.id,
            model_icon: item.homeData.icon,
          };
          del_modelFile(dt).then((res) => {
            let { code, msg } = res;
            if (code == 200) {
              this.editModelData(item);
              this.$message.success(msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // hanldeScroll(e) {
    //     // this.loading = true
    //     // 获取eagleMapContainer的真实高度
    //     const boxWidth = document.getElementById('eagleMapContainer').offsetWidth
    //     // 获取table_list的真实高度（浮动内容的真实高度）
    //     // const tableWidth = document.getElementById('table_list').offsetWidth
    //     // boxHeight和滑块浮动的高度相差小于50 && 不在加载中 && 不是最后一页
    //     // if (tableHeight - (e.target.scrollTop + boxHeight) < 50 && !this.loading && this.listPage < (this.tableList.length / 300)) {
    //     //     // 这里触发加载请求
    //     //     this.listPage = this.listPage + 1
    //     //     this.getList()
    //     // }
    // }
  },
};
</script>

<style scoped lang="scss">
.body {
  margin-left: 10px;

  .headerModel-body-new {
    border: 2px dashed #bbb;
    width: 290px;
    text-align: center;
    font-size: 13px;
    color: rgb(136, 136, 136);
    padding: 150px 0;
    box-shadow: 0 0 2px 1px rgb(231, 231, 231);
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;
  }
}

.isSss {
  opacity: 0;
}

.modelImgZero:hover .isSss,
.isSss:hover {
  opacity: 1;
}

.modelImg {
  font-size: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
  width: 290px;
  margin-top: 1px !important;
  margin-bottom: 10px;

  .modelImgZero {
    position: relative;
    height: 200px;

    .modelImgOne {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 99;
      background-color: #676767;
      color: #b6b6b6;
      padding: 0 5px;
      font-size: 12px;
      cursor: pointer;
    }

    .modelImgtwo {
      font-size: 12px;
      position: absolute;
      right: 10px;
      top: 0px;
      color: #a3a3a3;
    }

    .modelImgthree {
      position: absolute;
      bottom: 5px;
      left: 5px;
      display: flex;
    }

    .modelImgfour {
      font-size: 12px;
      position: absolute;
      top: 10px;
      left: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      display: inline-block;
      color: white;
      border-radius: 30px;
      padding: 5px 10px;
    }

    .modelImgfive {
      font-size: 12px;
      position: absolute;
      bottom: 0;
      right: 10px;
    }
  }

  .modelImg-list {
    background-color: #dddddd;
    font-size: 12px;

    .list {
      display: inline-block;
      width: 30px;
      color: black;
      padding: 8px 21.2px;
    }

    .tabColor {
      background-color: #0099ff;
      color: white;
    }
  }

  .modelData {
    font-size: 12px;
    padding: 10px;

    .el-button {
      width: 100%;
      margin: 0;
    }

    .upload-demo {
      padding-top: 10px !important;
      display: inline-block;
      border: 2px dashed rgb(226, 226, 226);
      margin-top: 10px;
    }

    .el-icon-upload {
      margin: 0;
      font-size: 30px;
      line-height: 10px;
    }

    .el-upload__text {
      display: block;
      margin: 0;
      padding: 0;
      line-height: 10px;
    }

    .el-textarea {
      margin-top: 10px;
    }
  }
}

::v-deep .el-upload-dragger {
  width: 290px;
  height: 200px;
  // border: none;
  background-color: #f2f2f2;
  border-radius: 0;
}

::v-deep .el-upload__text {
  font-size: 12px !important;
}

::v-deep .el-form-item__label {
  font-size: 12px;
  display: block;
  text-align: left;
  height: 25px;
  width: 100% !important;
}

::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

::v-deep .el-form-item {
  margin: 0;
}

::v-deep .modelData .el-upload .el-upload-dragger {
  width: 160px !important;
  height: 100px;
  background-color: white;
  border: 1px dashed #bbb;
  border-radius: 5px;
  margin-top: 10px;
  padding: 20px 10px 10px;
}

.modelImgtwo,
// .modelImgthree,
.modelImgfour,
.modelImgfive {
  z-index: 66;
  // opacity: 0;
}

// .upload-demo:hover~.modelImgtwo,
// .upload-demo:hover~.modelImgthree,
// .upload-demo:hover~.modelImgfour,
// .upload-demo:hover~.modelImgfive,
// .modelImgtwo:hover,
// .modelImgthree:hover,
// .modelImgfour:hover,
// .modelImgfive:hover {
//     opacity: 1;
//     cursor: pointer;
// }

.yuan {
  display: block;
  width: 8px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-right: 5px;
}

.yuan1 {
  background-color: #0099ff;
}

.el-button {
  width: 100%;
  margin-bottom: 10px;
}

.state {
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 13px;
}

.bodyMX {
  position: relative;

  .modelImgOne {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
    background-color: #676767bb;
    color: #b6b6b6;
    padding: 0 5px;
    font-size: 12px;
    cursor: pointer;
  }

  .delda {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 14px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    line-height: 8px;
    opacity: 0;
    transition: all 0.5s;
    cursor: pointer;
    z-index: 100;
  }
}

.bodyMX:hover .delda,
.delda:hover {
  opacity: 1;
}

::v-deep .el-dialog__body {
  border-top: 1px solid rgb(218, 218, 218);
  padding: 20px 0;
  margin: 0 20px;
}

.bd {
  border: 1px solid #409eff;
}

.bd1 {
  border: 1px solid rgba(255, 255, 255, 0);
}

.el-checkbox {
  margin: 0;
}

::v-deep .el-checkbox__input {
  position: absolute;
  right: 0;
  top: 0;
}

.zzz {
  ::v-deep .el-dialog__body {
    border: none;
  }
}

::v-deep .el-carousel__button {
  background-color: #0099ff;
  opacity: 0.3;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

::v-deep .el-carousel__indicators--horizontal {
  left: 0px;
  transform: translateX(0);
}

.delModelCss {
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 8px;
  color: #a3a3a3;
  cursor: pointer;
}

.hiddenText {
  display: flex;

  .textTitle {
    display: block;
    width: 80px;
  }

  .textBody {
    width: calc(100% - 80px);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

::v-deep .el-dialog {
  box-shadow: none;
}
</style>
