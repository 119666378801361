var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.video}},[(_vm.vrayArr.physicalPicture)?_c('el-form-item',{attrs:{"label":"实物图片"}},[(_vm.video.video.physicalPicture)?_c('div',_vm._l((_vm.video.video.physicalPicture),function(a,i){return _c('div',{key:i,staticClass:"xian",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"yin",staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","text-align":"center","background-color":"rgba(0, 0, 0, 0.5)"}},[_c('span',{staticStyle:{"color":"white","cursor":"pointer"},on:{"click":function($event){return _vm.del(_vm.video, 'physicalPicture_file', a)}}},[_vm._v(" 删除 ")])]),_c('img',{staticStyle:{"width":"100%","height":"160px","border":"1px solid #bbb","object-fit":"cover"},attrs:{"width":"100%","src":a}})])}),0):_vm._e(),_c('el-upload',{staticClass:"upload-demo",attrs:{"drag":"","action":"#","auto-upload":false,"show-file-list":false,"on-change":function (file, fileList) {
            _vm.handleAvatarSuccessZero(file, fileList, _vm.video);
          }}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("拖放，或点击上传")])])],1):_vm._e(),(_vm.vrayArr.installationVideo)?_c('el-form-item',{attrs:{"label":_vm.vrayArr.installationVideo.title}},[(_vm.video.video.installationVideo)?_c('div',_vm._l((_vm.video.video.installationVideo),function(a,i){return _c('div',{key:i,staticClass:"xian",staticStyle:{"position":"relative"}},[_c('video',{staticStyle:{"width":"100%"},attrs:{"controls":"","src":a}}),_c('div',{staticClass:"yin",staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","text-align":"center","background-color":"rgba(0, 0, 0, 0.5)"}},[_c('span',{staticStyle:{"color":"white","cursor":"pointer"},on:{"click":function($event){return _vm.del(_vm.video, 'installationVideo_file', a)}}},[_vm._v(" 删除 ")])])])}),0):_vm._e(),_c('el-upload',{staticClass:"upload-demo",attrs:{"drag":"","action":"#","auto-upload":false,"show-file-list":false,"on-change":function (file, fileList) {
            _vm.handleAvatarSuccessOne(file, fileList, _vm.video);
          }}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("拖放，或点击上传")])])],1):_vm._e(),(_vm.vrayArr.cadDrawing)?_c('el-form-item',{attrs:{"label":_vm.vrayArr.cadDrawing.title,"rules":[{ required: true, message: '请上传文件', trigger: 'blur' }]}},[(_vm.video.video.cadDrawing)?_c('div',_vm._l((_vm.video.video.cadDrawing),function(a,i){return _c('div',{key:i,staticClass:"xian",staticStyle:{"position":"relative"}},[_c('img',{staticStyle:{"width":"100%","height":"150px","border":"1px solid #bbb","object-fit":"cover"},attrs:{"width":"100%","src":a}}),_c('div',{staticClass:"yin",staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","text-align":"center","background-color":"rgba(0, 0, 0, 0.5)"}},[_c('span',{staticStyle:{"color":"white","cursor":"pointer"},on:{"click":function($event){return _vm.del(_vm.video, 'cadDrawing_file', a)}}},[_vm._v(" 删除 ")])])])}),0):_vm._e(),_c('el-upload',{staticClass:"upload-demo",attrs:{"drag":"","action":"#","multiple":false,"auto-upload":false,"show-file-list":false,"on-change":function (file, fileList) {
            _vm.handleAvatarSuccessTwo(file, fileList, _vm.video);
          }}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("拖放，或点击上传")])])],1):_vm._e(),(_vm.vrayArr.architectureDiagram)?_c('el-form-item',{attrs:{"label":_vm.vrayArr.architectureDiagram.title}},[(_vm.video.video.architectureDiagram)?_c('div',_vm._l((_vm.video.video.architectureDiagram),function(a,i){return _c('div',{key:i,staticClass:"xian",staticStyle:{"position":"relative"}},[_c('img',{staticStyle:{"width":"100%","height":"160px","border":"1px solid #bbb","object-fit":"cover"},attrs:{"width":"100%","src":a}}),_c('div',{staticClass:"yin",staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","text-align":"center","background-color":"rgba(0, 0, 0, 0.5)"}},[_c('span',{staticStyle:{"color":"white","cursor":"pointer"},on:{"click":function($event){return _vm.del(_vm.video, 'architectureDiagram_file', a)}}},[_vm._v(" 删除 ")])])])}),0):_vm._e(),_c('el-upload',{staticClass:"upload-demo",attrs:{"drag":"","action":"#","multiple":false,"auto-upload":false,"show-file-list":false,"on-change":function (file, fileList) {
            _vm.handleAvatarSuccessThree(file, fileList, _vm.video);
          }}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("拖放，或点击上传")])])],1):_vm._e(),_c('el-form-item',[_c('el-button',{attrs:{"type":" primary","size":"mini"},on:{"click":function($event){return _vm.submitForm(_vm.video)}}},[_vm._v("下一步")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }