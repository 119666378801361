<template>
  <div>
    <el-form :model="video" ref="ruleForm" class="demo-ruleForm">
      <el-form-item v-if="vrayArr.physicalPicture" label="实物图片">
        <div v-if="video.video.physicalPicture">
          <div
            class="xian"
            v-for="(a, i) in video.video.physicalPicture"
            :key="i"
            style="position: relative"
          >
            <div
              class="yin"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                background-color: rgba(0, 0, 0, 0.5);
              "
            >
              <span
                style="color: white; cursor: pointer"
                @click="del(video, 'physicalPicture_file', a)"
              >
                删除
              </span>
            </div>
            <img
              width="100%"
              :src="a"
              style="width: 100%; height: 160px; border: 1px solid #bbb; object-fit: cover"
            />
          </div>
        </div>
        <el-upload
          class="upload-demo"
          drag
          action="#"
          :auto-upload="false"
          :show-file-list="false"
          :on-change="
            (file, fileList) => {
              handleAvatarSuccessZero(file, fileList, video);
            }
          "
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">拖放，或点击上传</div>
        </el-upload>
      </el-form-item>
      <el-form-item v-if="vrayArr.installationVideo" :label="vrayArr.installationVideo.title">
        <div v-if="video.video.installationVideo">
          <div
            class="xian"
            v-for="(a, i) in video.video.installationVideo"
            :key="i"
            style="position: relative"
          >
            <video controls style="width: 100%" :src="a"></video>
            <div
              class="yin"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                background-color: rgba(0, 0, 0, 0.5);
              "
            >
              <span
                style="color: white; cursor: pointer"
                @click="del(video, 'installationVideo_file', a)"
              >
                删除
              </span>
            </div>
          </div>
        </div>
        <el-upload
          class="upload-demo"
          drag
          action="#"
          :auto-upload="false"
          :show-file-list="false"
          :on-change="
            (file, fileList) => {
              handleAvatarSuccessOne(file, fileList, video);
            }
          "
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">拖放，或点击上传</div>
        </el-upload>
      </el-form-item>
      <el-form-item
        v-if="vrayArr.cadDrawing"
        :label="vrayArr.cadDrawing.title"
        :rules="[{ required: true, message: '请上传文件', trigger: 'blur' }]"
      >
        <div v-if="video.video.cadDrawing">
          <div
            class="xian"
            v-for="(a, i) in video.video.cadDrawing"
            :key="i"
            style="position: relative"
          >
            <img
              width="100%"
              :src="a"
              style="width: 100%; height: 150px; border: 1px solid #bbb; object-fit: cover"
            />
            <div
              class="yin"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                background-color: rgba(0, 0, 0, 0.5);
              "
            >
              <span style="color: white; cursor: pointer" @click="del(video, 'cadDrawing_file', a)">
                删除
              </span>
            </div>
          </div>
        </div>
        <el-upload
          class="upload-demo"
          drag
          action="#"
          :multiple="false"
          :auto-upload="false"
          :show-file-list="false"
          :on-change="
            (file, fileList) => {
              handleAvatarSuccessTwo(file, fileList, video);
            }
          "
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">拖放，或点击上传</div>
        </el-upload>
      </el-form-item>
      <el-form-item v-if="vrayArr.architectureDiagram" :label="vrayArr.architectureDiagram.title">
        <div v-if="video.video.architectureDiagram">
          <div
            class="xian"
            v-for="(a, i) in video.video.architectureDiagram"
            :key="i"
            style="position: relative"
          >
            <img
              width="100%"
              :src="a"
              style="width: 100%; height: 160px; border: 1px solid #bbb; object-fit: cover"
            />
            <div
              class="yin"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                background-color: rgba(0, 0, 0, 0.5);
              "
            >
              <span
                style="color: white; cursor: pointer"
                @click="del(video, 'architectureDiagram_file', a)"
              >
                删除
              </span>
            </div>
          </div>
        </div>
        <el-upload
          class="upload-demo"
          drag
          action="#"
          :multiple="false"
          :auto-upload="false"
          :show-file-list="false"
          :on-change="
            (file, fileList) => {
              handleAvatarSuccessThree(file, fileList, video);
            }
          "
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">拖放，或点击上传</div>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type=" primary" size="mini" @click="submitForm(video)">下一步</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { modelsFileUpload, del_modelFile } from '@/common/modelList';
export default {
  name: 'videoList',
  props: {
    video: {
      type: Object,
    },
    vrayArr: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {
        // specification: {
        name: [
          { required: true, message: '请上传视频', trigger: 'blur' },
          // { type: 'number', message: '长度必须为数字值' }
        ],
        inputWidth: [
          { required: true, message: '请输入宽度', trigger: 'blur' },
          // { type: 'number', message: '宽度必须为数字值' }
        ],
        inputHeight: [
          { required: true, message: '请输入高度/厚度/直径', trigger: 'blur' },
          // { type: 'number', message: '高度/厚度/直径必须为数字值' }
        ],
        // }
      },
    };
  },
  methods: {
    handleAvatarSuccessZero(file, fileList, itemModel) {
      let dt = new FormData();
      dt.append('model_id', itemModel.id.model_id);
      // 多选
      // fileList.forEach((el, index) => {
      //     dt.append(`installationVideo_file[${index}]`, el.raw)
      // });
      dt.append('physicalPicture_file[0]', file.raw);

      modelsFileUpload(dt).then((res) => {
        // console.log(res);
        let { code, msg } = res;
        if (code == 200) {
          // console.log(itemModel);
          this.$emit('editModelData', itemModel);
          this.$message.success(msg);
        }
      });
    },
    handleAvatarSuccessOne(file, fileList, itemModel) {
      let dt = new FormData();
      dt.append('model_id', itemModel.id.model_id);
      // 多选
      // fileList.forEach((el, index) => {
      //     dt.append(`installationVideo_file[${index}]`, el.raw)
      // });
      dt.append('installationVideo_file[0]', file.raw);

      modelsFileUpload(dt).then((res) => {
        // console.log(res);
        let { code, msg } = res;
        if (code == 200) {
          this.$emit('editModelData', itemModel);
          this.$message.success(msg);
        }
      });
    },
    handleAvatarSuccessTwo(file, fileList, itemModel) {
      let dt = new FormData();
      dt.append('model_id', itemModel.id.model_id);
      dt.append('cadDrawing_file[0]', file.raw);

      modelsFileUpload(dt).then((res) => {
        // console.log(res);
        let { code, msg } = res;
        if (code == 200) {
          this.$emit('editModelData', itemModel);
          this.$message.success(msg);
        }
      });
    },
    handleAvatarSuccessThree(file, fileList, itemModel) {
      let dt = new FormData();
      dt.append('model_id', itemModel.id.model_id);
      dt.append('architectureDiagram[0]', file.raw);

      modelsFileUpload(dt).then((res) => {
        // console.log(res);
        let { code, msg } = res;
        if (code == 200) {
          this.$emit('editModelData', itemModel);
          this.$message.success(msg);
        }
      });
    },
    del(itemModel, name, item) {
      let dt = {
        model_id: itemModel.id.model_id,
        [name]: item,
      };
      del_modelFile(dt).then((res) => {
        let { code, msg } = res;
        if (code == 200) {
          this.$emit('editModelData', itemModel);
          this.$message.success(msg);
        }
      });
    },
    submitForm(e) {
      // console.log(e);
      // console.log(e.video.installationVideo);
      if (e.video.installationVideo != null || e.video.cadDrawing != null) {
        this.$emit('tab');
      } else {
        this.$message.warning('请上传必填信息');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-icon-upload {
  margin: 5px 0 0;
  font-size: 30px;
  line-height: 10px;
}

.el-upload__text {
  display: block;
  margin: 0;
  padding: 0;
  line-height: 10px;
}

.el-textarea {
  margin-top: 10px;
}

.el-button {
  width: 100%;
  margin: 0;
}

::v-deep .el-upload-dragger {
  width: 290px;
  height: 170px;
  border: none;
  background-color: #f2f2f2;
  border-radius: 0;
  margin-bottom: 5px;
}

::v-deep .el-upload__text {
  font-size: 12px !important;
  line-height: 50px;
}

::v-deep .el-form-item__label {
  font-size: 12px;
  display: block;
  text-align: left;
  // height: 30px;
  width: 100% !important;
  line-height: 30px;
  margin-bottom: 10px;
  float: none;
}

::v-deep .el-form-item__content {
  margin-left: 0 !important;
  line-height: 10px;
}

::v-deep .el-form-item {
  margin: 20px 0 10px !important;
}

::v-deep .modelData .el-upload .el-upload-dragger {
  width: 120px !important;
  height: 60px;
  background-color: white;
}

.yin {
  opacity: 0;
  line-height: 40px;
  font-size: 14px;
}

.xian {
  // border: 1px solid red;
  // height: 150px;
  font-size: 0;
}

.xian:hover .yin,
.yin:hover {
  opacity: 1;
}
</style>
