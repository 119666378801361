var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.vrayArr)?_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.basicsList.basicsList,"rules":_vm.rules.basicsList}},[(_vm.vrayArr.modelName)?_c('el-form-item',{attrs:{"label":_vm.vrayArr.modelName.title,"prop":"productName","rules":[
        {
          required: _vm.vrayArr.modelName.is_must == 1,
          message: ("请输入" + (_vm.vrayArr.modelName.title)),
          trigger: 'blur',
        } ]}},[_c('el-input',{attrs:{"size":"mini"},model:{value:(_vm.basicsList.basicsList.productName),callback:function ($$v) {_vm.$set(_vm.basicsList.basicsList, "productName", $$v)},expression:"basicsList.basicsList.productName"}})],1):_vm._e(),(_vm.vrayArr.modelNumber)?_c('el-form-item',{attrs:{"label":_vm.vrayArr.modelNumber.title,"prop":"productNum","rules":[
        {
          required: _vm.vrayArr.modelNumber.is_must == 1,
          message: ("请输入" + (_vm.vrayArr.modelNumber.title)),
          trigger: 'blur',
        } ]}},[_c('el-input',{attrs:{"size":"mini"},model:{value:(_vm.basicsList.basicsList.productNum),callback:function ($$v) {_vm.$set(_vm.basicsList.basicsList, "productNum", $$v)},expression:"basicsList.basicsList.productNum"}})],1):_vm._e(),(_vm.vrayArr.modelMaterial)?_c('el-form-item',{attrs:{"label":_vm.vrayArr.modelMaterial.title,"prop":"textureName","rules":[
        {
          required: _vm.vrayArr.modelMaterial.is_must == 1,
          message: ("请输入" + (_vm.vrayArr.modelMaterial.title)),
          trigger: 'blur',
        } ]}},[_c('el-input',{attrs:{"size":"mini"},model:{value:(_vm.basicsList.basicsList.textureName),callback:function ($$v) {_vm.$set(_vm.basicsList.basicsList, "textureName", $$v)},expression:"basicsList.basicsList.textureName"}})],1):_vm._e(),(_vm.vrayArr.constructionNumber)?_c('el-form-item',{attrs:{"label":_vm.vrayArr.constructionNumber.title,"prop":"constructionNumber","rules":[
        {
          required: _vm.vrayArr.constructionNumber.is_must == 1,
          message: ("请输入" + (_vm.vrayArr.constructionNumber.title)),
          trigger: 'blur',
        } ]}},[_c('el-input',{attrs:{"size":"mini"},model:{value:(_vm.basicsList.basicsList.constructionNumber),callback:function ($$v) {_vm.$set(_vm.basicsList.basicsList, "constructionNumber", $$v)},expression:"basicsList.basicsList.constructionNumber"}})],1):_vm._e(),(_vm.vrayArr.materialNumber)?_c('el-form-item',{attrs:{"label":_vm.vrayArr.materialNumber.title,"prop":"textureNum","rules":[
        {
          required: _vm.vrayArr.materialNumber.is_must == 1,
          message: ("请输入" + (_vm.vrayArr.materialNumber.title)),
          trigger: 'blur',
        } ]}},[_c('el-input',{attrs:{"size":"mini"},model:{value:(_vm.basicsList.basicsList.textureNum),callback:function ($$v) {_vm.$set(_vm.basicsList.basicsList, "textureNum", $$v)},expression:"basicsList.basicsList.textureNum"}})],1):_vm._e(),(_vm.vrayArr.publicBrandName)?_c('el-form-item',{attrs:{"label":_vm.vrayArr.publicBrandName.title,"prop":"publicBrandName","rules":[
        {
          required: _vm.vrayArr.publicBrandName.is_must == 1,
          message: ("请输入" + (_vm.vrayArr.publicBrandName.title)),
          trigger: 'blur',
        } ]}},[_c('el-input',{attrs:{"size":"mini"},model:{value:(_vm.basicsList.basicsList.publicBrandName),callback:function ($$v) {_vm.$set(_vm.basicsList.basicsList, "publicBrandName", $$v)},expression:"basicsList.basicsList.publicBrandName"}})],1):_vm._e(),(_vm.vrayArr.publicSupplyChainName)?_c('el-form-item',{attrs:{"label":_vm.vrayArr.publicSupplyChainName.title,"prop":"publicSupplyChainName","rules":[
        {
          required: _vm.vrayArr.publicSupplyChainName.is_must == 1,
          message: ("请输入" + (_vm.vrayArr.publicSupplyChainName.title)),
          trigger: 'blur',
        } ]}},[_c('el-input',{attrs:{"size":"mini"},model:{value:(_vm.basicsList.basicsList.publicSupplyChainName),callback:function ($$v) {_vm.$set(_vm.basicsList.basicsList, "publicSupplyChainName", $$v)},expression:"basicsList.basicsList.publicSupplyChainName"}})],1):_vm._e(),(_vm.vrayArr.heightFromGround)?_c('el-form-item',{attrs:{"label":_vm.vrayArr.heightFromGround.title,"prop":"heightFromGround","rules":[
        {
          required: _vm.vrayArr.heightFromGround.is_must == 1,
          message: ("请输入" + (_vm.vrayArr.heightFromGround.title)),
          trigger: 'blur',
        } ]}},[_c('el-input',{attrs:{"size":"mini"},model:{value:(_vm.basicsList.basicsList.heightFromGround),callback:function ($$v) {_vm.$set(_vm.basicsList.basicsList, "heightFromGround", $$v)},expression:"basicsList.basicsList.heightFromGround"}})],1):_vm._e(),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.submitForm('ruleForm', _vm.basicsList)}}},[_vm._v(" 下一步 ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }