<template>
  <div>
    <el-form :model="remark.remark" ref="ruleForm" class="demo-ruleForm">
      <el-form-item v-if="vrayArr.logistics" label="是否提供物流">
        <el-radio-group v-model="remark.remark.input1">
          <el-radio label="是"></el-radio>
          <el-radio label="否"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="vrayArr.isInstall" label="是否提供安装">
        <el-radio-group v-model="remark.remark.input2">
          <el-radio label="是"></el-radio>
          <el-radio label="否"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="vrayArr.afterSalesPolicy" label="售后政策">
        <el-input
          type="textarea"
          autosize
          placeholder="请输入内容"
          v-model="remark.remark.input3"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="vrayArr.remarks" label="备注(特性)">
        <el-input
          type="textarea"
          autosize
          placeholder="请输入内容"
          v-model="remark.remark.input4"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="vrayArr.installExplain" label="安装说明">
        <el-input
          type="textarea"
          autosize
          placeholder="请输入内容"
          v-model="remark.remark.input5"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="vrayArr.features" label="项目特征">
        <el-input
          type="textarea"
          autosize
          placeholder="请输入内容"
          v-model="remark.remark.input6"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="vrayArr.job_desc" label="工作内容">
        <el-input
          type="textarea"
          autosize
          placeholder="请输入内容"
          v-model="remark.remark.input7"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <div style="margin: 10px auto; text-align: center">
          <el-button type="primary" size="mini" @click="submitForm('ruleForm1', remark)">
            保存信息
          </el-button>
          <el-button type="primary" size="mini" @click="submitForm('ruleForm2', remark)">
            提交审核
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { model_baseStep4, newAdd_audit } from '@/common/modelList';
export default {
  name: 'remark',
  props: {
    remark: {
      type: Object,
    },
    vrayArr: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    submitForm(formName, remark) {
      if (formName == 'ruleForm1') {
        let dt = {
          classify_id: remark.id.classify_id,
          model_id: remark.id.model_id,
          logistics: remark.remark.input1 == '是' ? 1 : 0,
          isInstall: remark.remark.input2 == '是' ? 1 : 0,
          afterSalesPolicy: remark.remark.input3,
          remarks: remark.remark.input4,
          installExplain: remark.remark.input5,
          features: remark.remark.input6,
          job_desc: remark.remark.input7,
        };
        // console.log(dt);
        model_baseStep4(dt).then((res) => {
          let { code, msg } = res;
          if (code == 200) {
            this.$message.success(msg);
            this.$emit('tab');
          }
        });
      } else {
        // 新增模型审核
        let dt = {
          model_id: remark.id.model_id,
          logistics: remark.input1 == '是' ? 1 : 0,
          isInstall: remark.input2 == '是' ? 1 : 0,
          afterSalesPolicy: remark.input3,
          remarks: remark.input4,
          installExplain: remark.input5,
          features: remark.remark.input6,
          job_desc: remark.remark.input7,
        };
        newAdd_audit(dt).then((res) => {
          let { code, msg } = res;
          if (code == 200) {
            this.$message.success(msg);
            this.$emit('tab');
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  line-height: 30px;
}
</style>
