<template>
  <div>
    <div class="specificationList">
      <span
        v-for="(item, index) in specification.specification"
        :key="index"
        :class="index == tabS ? 'tad tadBGColor' : 'tad'"
        @click="tick(index)"
      >
        规格{{ index + 1 }}
      </span>
    </div>
    <div v-for="(item, index) in specification.specification" :key="index">
      <el-form
        v-if="index == tabS"
        :model="item"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <!-- <el-form-item v-if="('arrivalPeriod' in vrayArr)" :label="'规格' + (index + 1)" -->
        <el-form-item
          v-if="vrayArr.arrivalPeriod"
          :label="'规格' + (index + 1)"
          :prop="
            'specification.inputLenght' && 'specification.inputWidth' && 'specification.inputHeight'
          "
        >
          <el-input
            size="mini"
            v-model="item.specification.inputLenght"
            placeholder="长度"
          ></el-input>
          <el-input
            size="mini"
            v-model="item.specification.inputWidth"
            placeholder="宽度"
          ></el-input>
          <el-input
            size="mini"
            v-model="item.specification.inputHeight"
            placeholder="高度/厚度/直径"
          ></el-input>
        </el-form-item>
        <!-- 变量范围 -->
        <!-- <el-form-item v-if="('bracketVariable' in vrayArr)" :label="vrayArr.bracketVariable.title"> -->
        <el-form-item v-if="vrayArr.bracketVariable" :label="vrayArr.bracketVariable.title">
          <el-input size="mini" v-model="item.bracketVariable.inputMax" placeholder="最大">
            <span slot="suffix">mm</span>
          </el-input>
          <el-input size="mini" v-model="item.bracketVariable.inputMin" placeholder="最小">
            <span slot="suffix">mm</span>
          </el-input>
        </el-form-item>
        <!-- 型材、板材、龙骨（最小裁切尺寸） -->
        <el-form-item v-if="vrayArr.cutSize" :label="vrayArr.cutSize.title">
          <el-input size="mini" v-model="item.cutSize" placeholder="（最小裁切尺寸）">
            <span slot="suffix">mm</span>
          </el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.widthVariable" label="宽度变量范围">
          <el-input size="mini" v-model="item.widthVariable.inputMax" placeholder="最大">
            <span slot="suffix">mm</span>
          </el-input>
          <el-input size="mini" v-model="item.widthVariable.inputMin" placeholder="最小">
            <span slot="suffix">mm</span>
          </el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.highVariable" label="高度变量范围">
          <el-input size="mini" v-model="item.highVariable.inputMax" placeholder="最大">
            <span slot="suffix">mm</span>
          </el-input>
          <el-input size="mini" v-model="item.highVariable.inputMin" placeholder="最小">
            <span slot="suffix">mm</span>
          </el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.style" label="风格">
          <el-input size="mini" v-model="item.style"></el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.minCutSize" label="最小裁切尺寸">
          <el-input size="mini" v-model="item.minCutSize.one" placeholder="长">
            <span slot="suffix">mm</span>
          </el-input>
          <el-input size="mini" v-model="item.minCutSize.two" placeholder="宽">
            <span slot="suffix">mm</span>
          </el-input>
          <el-input size="mini" v-model="item.minCutSize.three" placeholder="厚">
            <span slot="suffix">mm</span>
          </el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.minstretch" :label="vrayArr.minstretch.title">
          <el-input size="mini" v-model="item.minstretch.length" placeholder="长">
            <span slot="suffix">mm</span>
          </el-input>
          <el-input size="mini" v-model="item.minstretch.width" placeholder="宽">
            <span slot="suffix">mm</span>
          </el-input>
          <el-input size="mini" v-model="item.minstretch.height" placeholder="厚">
            <span slot="suffix">mm</span>
          </el-input>
        </el-form-item>

        <el-form-item v-if="vrayArr.maxstretch" :label="vrayArr.maxstretch.title">
          <el-input size="mini" v-model="item.maxstretch.length" placeholder="长">
            <span slot="suffix">mm</span>
          </el-input>
          <el-input size="mini" v-model="item.maxstretch.width" placeholder="宽">
            <span slot="suffix">mm</span>
          </el-input>
          <el-input size="mini" v-model="item.maxstretch.height" placeholder="厚">
            <span slot="suffix">mm</span>
          </el-input>
        </el-form-item>

        <el-form-item v-if="vrayArr.color" label="颜色">
          <el-input size="mini" v-model="item.color"></el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.doorOpeningDirection" label="开门方向">
          <el-input size="mini" v-model="item.doorOpeningDirection"></el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.placementMode" label="摆放方式">
          <el-input size="mini" v-model="item.placementMode"></el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.functionalHardware" label="功能五金">
          <el-radio-group v-model="item.functionalHardware.is">
            <el-radio label="是"></el-radio>
            <el-radio label="否"></el-radio>
          </el-radio-group>
          <el-input
            v-if="item.functionalHardware.is == '是'"
            size="mini"
            v-model="item.functionalHardware.name"
            placeholder="名称"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.openingSize" label="开孔尺寸">
          <el-input size="mini" v-model="item.openingSize"></el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.power" label="功率">
          <el-input size="mini" v-model="item.power"></el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.colorTemperature" label="色温">
          <el-input size="mini" v-model="item.colorTemperature"></el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.illumination" label="照度">
          <el-input size="mini" v-model="item.illumination"></el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.diopter" label="屈光度">
          <el-input size="mini" v-model="item.diopter"></el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.minimumShipment" label="最小出货量">
          <el-input size="mini" v-model="item.minimumShipment"></el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.factoryPrice" label="出厂单价">
          <el-input size="mini" v-model="item.factoryPrice">
            <span slot="prefix">￥</span>
          </el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.commission" label="平台可支配佣金">
          <el-input size="mini" v-model="item.commission">
            <span slot="prefix">￥</span>
          </el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.inStock" label="是否库存">
          <el-radio-group v-model="item.inStock.is">
            <el-radio label="是"></el-radio>
            <el-radio label="否"></el-radio>
          </el-radio-group>
          <el-input
            v-if="item.inStock.is == '是'"
            size="mini"
            v-model="item.inStock.inputRepertoryNum"
            placeholder="请填写库存数量"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="vrayArr.arrivalPeriod" label="到货周期">
          <el-input size="mini" v-model="item.arrivalPeriod">
            <span slot="suffix">天</span>
          </el-input>
        </el-form-item>
        <div style="text-align: center; margin: 10px">
          <span @click="add">
            <i class="el-icon-circle-plus-outline"></i>
            添加新规格
          </span>
        </div>
      </el-form>
    </div>
    <el-button type="primary" size="mini" @click="submitForm(specification)">下一步</el-button>
  </div>
</template>

<script>
import { step2 } from '@/common/modelList';
export default {
  name: 'specification',
  props: {
    specification: {
      type: Object,
    },
    vrayArr: {
      type: Object,
    },
    check: {
      type: Array,
    },
  },
  data() {
    return {
      tab: 3,
      tabS: 0,
      index: 0,
      rules: {
        specification: {
          inputLenght: [
            { required: true, message: '请输入长度', trigger: 'blur' },
            // { type: 'number', message: '长度必须为数字值' }
          ],
          inputWidth: [
            { required: true, message: '请输入宽度', trigger: 'blur' },
            // { type: 'number', message: '宽度必须为数字值' }
          ],
          inputHeight: [
            { required: true, message: '请输入高度/厚度/直径', trigger: 'blur' },
            // { type: 'number', message: '高度/厚度/直径必须为数字值' }
          ],
        },
      },
    };
  },
  methods: {
    add() {
      this.$emit('addSpecification');
    },
    submitForm(specification) {
      let condition = true;
      specification.chartletImg.forEach((element) => {
        if (
          element.specification.inputHeight == '' ||
          element.specification.inputLenght == '' ||
          element.specification.inputWidth == ''
        ) {
          this.$message.warning('请填写规格');
          condition = false;
          return;
        }
      });
      if (condition) {
        let dt = {
          classify_id: specification.id.classify_id,
          model_id: specification.id.model_id,
        };
        specification.specification.forEach((el, index) => {
          dt['specs_' + (index + 1)] = el;
        });
        dt = JSON.stringify(dt);
        // console.log(dt, '0');
        step2({ json_data: dt }).then((res) => {
          let { code, msg } = res;
          if (code == 200) {
            this.$message.success(msg);
            this.$emit('tab');
          }
        });
      }
    },
    submitFormNew() {
      let condition = true;
      this.specification.chartletImg.forEach((element) => {
        if (
          element.specification.inputHeight == '' ||
          element.specification.inputLenght == '' ||
          element.specification.inputWidth == ''
        ) {
          this.$message.warning('请填写规格');
          condition = false;
          return;
        }
      });
      if (condition) {
        let dt = {
          classify_id: this.specification.id.classify_id,
          model_id: this.specification.id.model_id,
        };
        this.specification.specification.forEach((el, index) => {
          dt['specs_' + (index + 1)] = el;
        });
        dt = JSON.stringify(dt);
        // console.log(dt, '1');
        step2({ json_data: dt }).then((res) => {
          let { code, msg } = res;
          if (code == 200) {
            this.$message.success(msg);
          }
        });
      }
    },
    tick(index) {
      this.tabS = index;
      this.$emit('guigeImg', index);
    },
  },
};
</script>

<style lang="scss" scoped>
.specificationList {
  .tad {
    background-color: #d9d9d9;
    padding: 5px 10px;
    border-radius: 15px;
    margin-left: 5px;
    margin-bottom: 5px;
    display: inline-block;
  }

  .tadBGColor {
    background-color: #0099ff;
    color: white;
  }
}

.el-button {
  width: 100%;
  margin: 0;
}
</style>
