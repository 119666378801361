var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"components"},[_c('div',{staticClass:"header"},[_c('div',[_c('h4',{staticClass:"header-title"},[_vm._v("分类下贴图")]),_c('p',{staticClass:"header-subtitle"},[_vm._v("当前已上传贴图"+_vm._s(_vm.listData.total)+"个")])]),(_vm.tabGps != 0)?_c('div',[_c('button',{staticClass:"btn",on:{"click":_vm.add}},[_vm._v("新增贴图")]),_c('button',{staticClass:"btn",on:{"click":_vm.keepBTN}},[_vm._v("保存")]),_c('button',{staticClass:"btn",on:{"click":_vm.deleteChartletList}},[_vm._v("清空")])]):_vm._e()]),_c('div',{staticClass:"content",on:{"scroll":_vm.scrollChange}},_vm._l((_vm.listData.data),function(item,index){return _c('div',{key:index,staticClass:"content-item"},[_c('el-card',{attrs:{"body-style":{ padding: '0px' }}},[_c('div',{staticClass:"content-item-header"},[_c('div',{staticClass:"content-item-header-bottom"},[(!item.whole_picture)?_c('el-upload',{staticClass:"content-item-header-upload",attrs:{"drag":"","action":"#","multiple":false,"limit":1,"auto-upload":false,"show-file-list":false,"on-change":function (file) {
                  _vm.handleAvatarSuccess(file, item);
                }}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v(" 拖放或 "),_c('em',[_vm._v("点击上传")])])]):_c('el-image',{staticClass:"content-item-header-img",attrs:{"src":item.whole_picture,"fit":"cover","preview-src-list":[item.whole_picture]}})],1),(_vm.tabGps != 0)?_c('div',{staticClass:"content-item-header-button-group"},[(item.id > 0)?_c('span',{on:{"click":function($event){return _vm.editClassifyDialog.showFunc(item.id, index)}}},[_vm._v(" 修改分类 ")]):_vm._e(),_c('span',{on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("编辑")]),_c('span',{on:{"click":function($event){return _vm.delClassify(index)}}},[_vm._v("删除")])]):_vm._e()]),_c('div',{staticClass:"content-item-body"},[(item.id > 0)?[(item.is_edit)?[_c('el-form',[_c('el-form-item',{attrs:{"rules":{
                    required: true,
                    message: '请输入贴图名称',
                    trigger: 'change',
                  }}},[_c('el-tooltip',{attrs:{"content":"请输入贴图名称"}},[_c('el-input',{staticClass:"content-item-body-form-item",attrs:{"size":"small","placeholder":"贴图名称"},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})],1)],1),_c('el-form-item',{attrs:{"rules":{
                    required: true,
                    message: '请输入贴图编号',
                    trigger: 'change',
                  }}},[_c('el-tooltip',{attrs:{"content":"请输入贴图编号"}},[_c('el-input',{staticClass:"content-item-body-form-item",attrs:{"size":"small","placeholder":"贴图编号"},model:{value:(item.number),callback:function ($$v) {_vm.$set(item, "number", $$v)},expression:"item.number"}})],1)],1),_c('el-form-item',[_c('el-tooltip',{attrs:{"content":"厂家编号"}},[_c('el-input',{staticClass:"content-item-body-form-item",attrs:{"size":"small","placeholder":"厂家编号"},model:{value:(item.constructionNumber),callback:function ($$v) {_vm.$set(item, "constructionNumber", $$v)},expression:"item.constructionNumber"}})],1)],1),_c('el-form-item',[_c('el-tooltip',{attrs:{"content":"公库品牌名"}},[_c('el-input',{staticClass:"content-item-body-form-item",attrs:{"size":"small","placeholder":"公库品牌名"},model:{value:(item.publicBrandName),callback:function ($$v) {_vm.$set(item, "publicBrandName", $$v)},expression:"item.publicBrandName"}})],1)],1),_c('el-form-item',[_c('el-tooltip',{attrs:{"content":"公库供应链名"}},[_c('el-input',{staticClass:"content-item-body-form-item",attrs:{"size":"small","placeholder":"公库供应链名"},model:{value:(item.publicSupplyChainName),callback:function ($$v) {_vm.$set(item, "publicSupplyChainName", $$v)},expression:"item.publicSupplyChainName"}})],1)],1),_c('div',{staticClass:"save-bar"},[_c('a',{attrs:{"type":"text"},on:{"click":function($event){return _vm.saveData(item)}}},[_vm._v("Save")])])],1)]:[_c('div',[_c('p',[_vm._v(_vm._s(item.title ? item.title : '-'))]),_c('p',[_vm._v(_vm._s(item.number ? item.number : '-'))]),_c('p',[_vm._v(" "+_vm._s(item.constructionNumber ? item.constructionNumber : '-')+" ")]),_c('p',[_vm._v(_vm._s(item.publicBrandName ? item.publicBrandName : '-'))]),_c('p',[_vm._v(" "+_vm._s(item.publicSupplyChainName ? item.publicSupplyChainName : '-')+" ")])])]]:[_c('el-form',{ref:"newForm",refInFor:true},[_c('el-form-item',{attrs:{"rules":{
                  required: true,
                  message: '请输入贴图名称',
                  trigger: 'change',
                }}},[_c('el-input',{staticClass:"content-item-body-form-item",attrs:{"size":"small","placeholder":"贴图名称"},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})],1),_c('el-form-item',{attrs:{"rules":{
                  required: true,
                  message: '请输入贴图编号',
                  trigger: 'change',
                }}},[_c('el-input',{staticClass:"content-item-body-form-item",attrs:{"size":"small","placeholder":"贴图编号"},model:{value:(item.number),callback:function ($$v) {_vm.$set(item, "number", $$v)},expression:"item.number"}})],1),_c('el-form-item',{attrs:{"rules":{
                  required: true,
                  message: '请输入厂家编号',
                  trigger: 'change',
                }}},[_c('el-input',{staticClass:"content-item-body-form-item",attrs:{"size":"small","placeholder":"厂家编号"},model:{value:(item.constructionNumber),callback:function ($$v) {_vm.$set(item, "constructionNumber", $$v)},expression:"item.constructionNumber"}})],1),_c('el-form-item',[_c('el-input',{staticClass:"content-item-body-form-item",attrs:{"size":"small","placeholder":"公库品牌名"},model:{value:(item.publicBrandName),callback:function ($$v) {_vm.$set(item, "publicBrandName", $$v)},expression:"item.publicBrandName"}})],1),_c('el-form-item',[_c('el-input',{staticClass:"content-item-body-form-item",attrs:{"size":"small","placeholder":"公库供应链名"},model:{value:(item.publicSupplyChainName),callback:function ($$v) {_vm.$set(item, "publicSupplyChainName", $$v)},expression:"item.publicSupplyChainName"}})],1)],1)]],2)])],1)}),0),_c('el-dialog',{attrs:{"title":"修改贴图分类","visible":_vm.editClassifyDialog.isShow,"width":"450px","center":"","close-on-click-modal":false},on:{"update:visible":function($event){return _vm.$set(_vm.editClassifyDialog, "isShow", $event)}}},[_c('el-cascader',{staticStyle:{"width":"400px"},attrs:{"options":_vm.editClassifyDialog.options,"props":{ label: 'name', value: 'id' }},model:{value:(_vm.editClassifyDialog.formData.classify_ids),callback:function ($$v) {_vm.$set(_vm.editClassifyDialog.formData, "classify_ids", $$v)},expression:"editClassifyDialog.formData.classify_ids"}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.editClassifyDialog.isShow = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.editClassifySubmit}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }